<template>
    <div v-if="selectedNews.length" class= "first-section-campaign-mode-container">
      <div class="first-section-campaign-header">
        <div class="first-section-campaign-header-main-img">
          <img v-if="selectedNews[0] &&selectedNews[0].featured_images && selectedNews[0].featured_images[0]"
               :src="getUrl()+selectedNews[0].featured_images[0].image"
               alt="no image for this item">
          <img v-else alt="this news must contain image">
        </div>
        <div class="first-section-campaign-header-two-img">
          <div>
            <img v-if="selectedNews[1] && selectedNews[1].featured_images && selectedNews[1].featured_images[0]"
                 :src="getUrl()+selectedNews[1].featured_images[0].image"
                 alt="no image for this item">
            <img v-else alt="this news must contain image">
          </div>
          <div>

            <img v-if="selectedNews[2] && selectedNews[2].featured_images && selectedNews[2].featured_images[0]"
                 :src="getUrl()+selectedNews[2].featured_images[0].image"
                 alt="no image for this item">
            <img v-else alt="this news must contain image">
          </div>

        </div>
      </div>
      <div class="first-section-campaign-header-bottom">
        <div>
          <img   v-if="selectedNews[3] && selectedNews[3].featured_images && selectedNews[3].featured_images[0]"
            :src="getUrl()+selectedNews[3].featured_images[0].image"
                alt="no image for this item">
          <img v-else alt="this news must contain image">
        </div>

        <div>
          <img  :src="getUrl()+selectedNews[4].featured_images[0].image"
                v-if="selectedNews[4] && selectedNews[4].featured_images && selectedNews[4].featured_images[0]"
                alt="no image for this item">
          <img v-else alt="this news must contain image">
        </div>

        <div>
          <img  :src="getUrl()+selectedNews[5].featured_images[0].image"
                v-if="selectedNews[5] && selectedNews[5].featured_images && selectedNews[5].featured_images[0]"
                alt="no image for this item">
          <img v-else-if="selectedNews[5]" alt="this news must contain image">
          <img v-else alt="You must select one more news">
        </div>

      </div>
    </div>
</template>

<script>
import SelectNewsModal from '@/pages/visualEditors/homePage/SelectNewsModal.vue';

export default {
  name: 'first-section-campaign-mode',
  components: {
    SelectNewsModal
  },
  props: {
    selectedNews: {
      type: Array,
    }
  },
  mounted() {
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1);
  },
  data() {

    return {
      lang: null,
      modalSelectedList: [],
      selectNewsModalVisibility: false,
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
  }

}
</script>
<style>

.first-section-campaign-mode-container{
  overflow: hidden;
  padding: 0 20px;
  height: 500px;
  width:100%;
}
.first-section-campaign-mode-container  img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 1px;
}
.first-section-campaign-mode-container .first-section-campaign-header{
  height: 60%;
  display: flex;
  overflow: hidden;
}
.first-section-campaign-mode-container .first-section-campaign-header .first-section-campaign-header-two-img{
  flex-basis: 33%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}
.first-section-campaign-header-two-img div{
   height: 50%;
}
.first-section-campaign-mode-container .first-section-campaign-header .first-section-campaign-header-main-img{
  flex-basis: 67%;
}
.first-section-campaign-mode-container .first-section-campaign-header-bottom{
  height: 40%;
  display: flex;
}
.first-section-campaign-mode-container .first-section-campaign-header-bottom div{
  flex-basis: 33%;
}
.first-section-campaign-mode-container .first-section-campaign-header-bottom div:nth-child(2){
  flex-basis: 34%;
}
</style>
