<template>
      <div class="row-news-container">
        <div v-bind:class="{ 'news-section-column-1-campaign-mode': campaignMode, }" class="news-section-column-1">
          <category-classification-column-with-header
            :typeList="[{name :'category'},{name:'classification'}]" :section-key="sectionKey + '_column_1'" :sectionData="column1"
            :categoriesData="categoriesData" :classificationData="classificationData"
            @saveData="saveData">

          </category-classification-column-with-header>
        </div>
        <div class="news-section-column-line">
          <div class="line">
          </div>
        </div>
        <div v-bind:class="{ 'news-section-column-2-campaign-mode': campaignMode, }" class="news-section-column-2">
          <category-classification-column-list
            :typeList="[{name :'category'},{name:'classification'}]" :section-key="sectionKey + '_column_2'" :sectionData="column2"
            :categoriesData="categoriesData" :classificationData="classificationData"
            @saveData="saveData">

          </category-classification-column-list>
        </div>
      </div>
</template>

<script>

import CategoryClassificationColumnWithHeader
  from "@/pages/visualEditors/homePage/CategoryClassificationTwoCloumns/CategoryClassificationColumnWithHeader";
import CategoryClassificationColumnList
  from "@/pages/visualEditors/homePage/CategoryClassificationTwoCloumns/CategoryClassificationColumnList";
export default {
  name: 'category-classification-two-columns-section',
  components: {
    CategoryClassificationColumnList,
    CategoryClassificationColumnWithHeader
  },
  watch: {
    sectionData: {
      handler: function (newValue) {
        this.setSectionData();
      },
      deep: true
    }
  },
  props: {
    typeList:{
      type: Array,
    },
    sectionData: {
      type: Object,
    },
    sectionKey: {
      type: String,
    },
    classificationData: {
      type: Array
    },
    categoriesData: {
      type: Array
    },
    campaignMode: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1);
  },
  data() {

    return {
      lang:null,
      modalSelectedList: [],
      column1:{},
      column2:{},
      selectRowModalVisibility: false,
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    openSelectRowModal() {
      this.selectRowModalVisibility = true;
    },
    closeSelectRowModal() {
      this.selectRowModalVisibility = false;
    },
    setSectionData() {
      if(this.sectionData && this.sectionData.column1){
        this.column1 = this.sectionData.column1;
      }else{
        this.column1 = {};
      }

      if(this.sectionData && this.sectionData.column2){
        this.column2 = this.sectionData.column2;
      }else{
        this.column2 = {};
      }
    },
    saveData(value) {
      this.$emit('saveData', value);
    }
  }

}
</script>
<style>
.row-news-container{
  display: flex;
}
.news-section-column-1{
  flex-basis: 40%;
}
.news-section-column-1-campaign-mode{
  flex-basis: 49%;
}
.news-section-column-line{
  flex-basis: 1%;
  position: relative;
  margin: 90px 0 30px;
}
.news-section-column-2{
  flex-basis: 40%;
}
.news-section-column-2-campaign-mode{
  flex-basis: 49%;
}
.section-title{
  margin-bottom: 35px;
}
</style>
