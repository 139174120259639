<template>
  <div class="container">
    <h3 class="section-title">{{ sectionTitle }}</h3>
    <div class="row section-container">
      <div class="col-5 text-news-container">
        <template v-for="(textNews,textNewsIndex) in textNews">
          <news-text-card class="text-news" :item="textNews"></news-text-card>
        </template>
      </div>
      <div class="col-1">
         <div class="line">
         </div>
      </div>
      <div v-if="campaignMode" class="col-5">
        <news-card v-if="newsWithImages && newsWithImages.length" :item="newsWithImages[0]"></news-card>
      </div>
      <div v-else class="col-5">
        <template v-for="(imageNews,imageNewsIndex) in newsWithImages">
          <news-horizontal-card class="image-news" :item="imageNews"></news-horizontal-card>
        </template>
      </div>

    </div>
  </div>
</template>

<script>

import NewsTextCard from "@/pages/visualEditors/homePage/NewsTextCard";
import NewsHorizontalCard from "@/pages/visualEditors/homePage/NewsHorizontalCard";
import NewsCard from "@/pages/visualEditors/homePage/NewsCard";

export default {
  name: 'category-classification-row-section-with-two-columns',
  components: {NewsCard, NewsHorizontalCard, NewsTextCard},
  props: {
    sectionData: {
      type: Object,
    },
    campaignMode: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    sectionData: {
      handler: function (newValue) {
        this.setSectionData();
      },
      deep: true
    }
  },
  mounted() {
    this.setSectionData();
  },
  data() {
    return {
      lang: null,
      sectionTitle: '',
      newsWithImages: [],
      textNews: [],
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    async setSectionData() {
      await this.resetSectionData();
      if (this.sectionData && this.sectionData.item) {
        this.sectionTitle = this.sectionData.item.name;
        for (const news of this.sectionData.news) {
          if (this.textNews.length < 3) {
            this.textNews.push(news);
          } else {
            if (this.newsWithImages.length < 2) {
              this.newsWithImages.push(news);
            }
          }
        }
      } else {
        await this.resetSectionData();
      }
    },
    async resetSectionData() {
      return new Promise(async (resolve, reject) => {
        this.sectionTitle = '';
        this.newsWithImages = [];
        this.textNews = [];
        return resolve();
      });
    }

  }

}
</script>
<style>
.section-container{
  justify-content: space-evenly;
}
.section-title {
  margin: 10px 50px;
  font-weight: bold;
}
.text-news{
  margin-top: 10px;
}
.image-news{
  margin-top: 20px;
}
</style>
