<template>
  <div ref="groupForm" class="row" :class="dir!=='rtl'?'null':'rtl-class'">

    <div class="col-12">
      <first-section :key="componentKey" :selectedNews="firstSectionData" @saveData="saveSectionData"></first-section>
    </div>

    <div class="col-12">
      <row-section :news-limit="5" :view-type="'two_columns'" :typeList="[{name :'category'},{name:'classification'}]"
                   :key="componentKey" :section-key="'home_page_section_3'" :sectionData="thirdSectionData"
                   :categoriesData="categoriesData" :classificationData="classificationData"
                   @saveData="saveSectionData"></row-section>
    </div>

    <div class="col-12">
      <row-section :news-limit="5" :view-type="'case_with_main_news'" :typeList="[{name :'case'}]" :key="componentKey"
                   :section-key="'home_page_section_4'" :sectionData="fourthSectionData"
                   :categoriesData="categoriesData" :classificationData="classificationData"
                   @saveData="saveSectionData"></row-section>
    </div>

    <div class="col-12">
      <category-classification-two-columns-section :typeList="[{name :'category'},{name:'classification'}]"
                                                   :key="componentKey" :section-key="'home_page_section_5'"
                                                   :sectionData="fifthSectionData"
                                                   :categoriesData="categoriesData"
                                                   :classificationData="classificationData"
                                                   @saveData="saveSectionData"></category-classification-two-columns-section>
    </div>

    <div class="col-12">
      <row-section :news-limit="5" :view-type="'one_column_with_video'"
                   :typeList="[{name :'category'},{name:'classification'}]" :key="componentKey"
                   :section-key="'home_page_section_6'" :sectionData="sixthSectionData"
                   :categoriesData="categoriesData" :classificationData="classificationData"
                   @saveData="saveSectionData"></row-section>
    </div>

    <div class="col-12">
      <row-section :news-limit="6" :view-type="'as_gallery'" :typeList="[{name :'category'},{name:'classification'}]"
                   :key="componentKey" :section-key="'home_page_section_7'" :sectionData="seventhSectionData"
                   :categoriesData="categoriesData" :classificationData="classificationData"
                   @saveData="saveSectionData"></row-section>
    </div>

    <div class="col-12">
      <row-section :news-limit="6" :view-type="'case_news_list'" :typeList="[{name :'case'}]" :key="componentKey"
                   :section-key="'home_page_section_8'" :sectionData="eighthSectionData"
                   :categoriesData="categoriesData" :classificationData="classificationData"
                   @saveData="saveSectionData"></row-section>
    </div>
    <div class="col-12">
      <row-section :news-limit="2" :view-type="'one_column_two_news'"
                   :typeList="[{name :'category'},{name:'classification'}]" :key="componentKey"
                   :section-key="'home_page_section_9'" :sectionData="ninthSectionData"
                   :categoriesData="categoriesData" :classificationData="classificationData"
                   @saveData="saveSectionData"></row-section>
    </div>
  </div>
</template>
<script>
import FirstSection from '@/pages/visualEditors/homePage/firstSection/FirstSection';
import SecondSectionRow1 from '@/pages/visualEditors/homePage/secondSection/SecondSectionRow1';
import SecondSectionRow2 from '@/pages/visualEditors/homePage/secondSection/SecondSectionRow2';
import SecondSectionRow3 from '@/pages/visualEditors/homePage/secondSection/SecondSectionRow3';
import SecondSectionRow4 from '@/pages/visualEditors/homePage/secondSection/SecondSectionRow4';
import RowSection from "@/pages/visualEditors/homePage/RowSection";
import CategoryClassificationTwoColumnsSection
  from "@/pages/visualEditors/homePage/CategoryClassificationTwoCloumns/CategoryClassificationTwoColumnsSection";
import LiveCoverageHeaderSection from "./liveCoverageHeaderSection";

export default {
  name: 'home-page-regular-mode-visual-editor',
  components: {
    LiveCoverageHeaderSection,
    CategoryClassificationTwoColumnsSection,
    RowSection,
    FirstSection,
    SecondSectionRow1,
    SecondSectionRow2,
    SecondSectionRow3,
    SecondSectionRow4,
  },
  props: ['draft'],
  watch: {
    $route(to, from) {
      this.resetSectionsData();
      this.componentKey++;
      this.initializeComponent();
    }
  },
  data() {

    return {
      componentKey: 0,
      categoriesData: [],
      classificationData: [],
      lang: null,
      dir: null,
      firstSectionData: [],
      thirdSectionData: {},
      fourthSectionData: {},
      fifthSectionData: {},
      sixthSectionData: {},
      seventhSectionData: {},
      eighthSectionData: {},
      ninthSectionData: {},
      liveCoverageHeaderData: {}
    }
  },
  mounted() {
    this.initializeComponent();
  },
  methods: {
    async initializeComponent() {
      const path = window.location.href;
      this.lang = path.substring(path.lastIndexOf('/') + 1);
      this.loader = this.$loading.show({
        container: this.$refs.groupForm
      });
      const rtlLang = ['ar'];
      if (rtlLang.includes(this.lang)) {
        this.dir = 'rtl';
      } else {
        this.dir = 'ltr';
      }
      await this.getAllCategories();
      await this.getAllClassification();
      await this.getVisualEditor();
      this.loader.hide();
    },
    async getAllCategories() {
      return new Promise(async (resolve, reject) => {
        let data = {
          page: 1,
          pageSize: 1000,
          lang: this.lang
        };
        let requestUrl = 'categories/index';
        let request = this.axios.post(requestUrl, data);
        request.then((response) => {
          this.categoriesData = response.data.data;
        }).catch((error) => {
        }).finally(() => {
          return resolve(true);
        });
      });

    },
    async getAllClassification() {
      return new Promise(async (resolve, reject) => {
        let data = {
          page: 1,
          pageSize: 1000,
          lang: this.lang
        };
        let requestUrl = 'classifications/index'
        let request = this.axios.post(requestUrl, data);
        request.then((response) => {
          this.classificationData = response.data.data;
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          return resolve(true);
        });
      });
    },
    saveSectionData(data) {
      this.axios.post("visual-editor/save-content", data).then((response) => {
        this.getVisualEditor();
      }).catch((error) => {
        console.error(error);
      })
    },
    resetSectionsData() {
      this.firstSectionData = [];
      this.thirdSectionData = {};
      this.fourthSectionData = {};
      this.fifthSectionData = {};
      this.sixthSectionData = {};
      this.seventhSectionData = {};
      this.eighthSectionData = {};
      this.ninthSectionData = {};
    },
    async getVisualEditor() {
      return new Promise(async (resolve, reject) => {
        this.axios.get("visual-editor/get-content?lang=" + this.lang).then((response) => {
          this.firstSectionData = response.data.homePageSection1;
          this.thirdSectionData = response.data.homePageSection3;
          this.fourthSectionData = response.data.homePageSection4;
          this.fifthSectionData = response.data.homePageSection5;
          this.sixthSectionData = response.data.homePageSection6;
          this.seventhSectionData = response.data.homePageSection7;
          this.eighthSectionData = response.data.homePageSection8;
          this.ninthSectionData = response.data.homePageSection9;
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          return resolve(true);
        });
      });
    }
  }
}
</script>
<style>

.rtl-class {
  direction: rtl;
  text-align: right;
}

fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  margin: 5px 5px 1.5em 5px !important;
  padding: 0px !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
  font-size: 1.2em !important;
  color: #000;
  background-color: #0000;
}

.visual-editor-btn {
  cursor: pointer;
}

.news-card-title {
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  max-height: 40px;
}

.news-card-excerpt {
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  margin: 5px 0px;
  max-height: 120px;
}

.news-card-tag {
  font-size: 12px;
}

.news-card-source, .news-card-date {
  font-size: 10px;
}

.line {
  background-color: #000000;
}
</style>

