<template>
  <div class="container">
    <h3 class="section-title">{{ sectionTitle }}</h3>
    <div class="row">
      <div v-bind:class="{ 'col-9': !campaignMode , 'col-12': campaignMode, }" >
        <template v-if="mainNews">
          <div class="d-flex align-items-start flex-column video-news-header">
            <div class=" w-100">
              <div class="px-1">
                <video v-if="mainNews.uploaded_video" controls class="card-img-top w-100 h-100">
                  <source :src="getUrl()+mainNews.uploaded_video">
                  Your browser does not support the video tag.
                </video>
                <div v-else-if="mainNews.embedded_video" v-html="mainNews.embedded_video"
                     class="card-img-top embed-video w-100 h-100">

                </div>
                <img class="card-img-top w-100 h-100" v-else alt="this news must contain video">
              </div>
            </div>
            <p class="news-card-title mb-1">{{ mainNews.title }}</p>
            <p class="news-card-excerpt text-muted">{{ mainNews.excerpt }}</p>
            <p v-if="mainNews.category" :style="'color:' + mainNews.category.color" class="news-card-tag mt-auto">
              <template v-for="(item, index) in mainNews.tags">
                <span>{{ item.name }}</span>
                <span v-if="index+1 !== mainNews.tags.length" class="vertical-line mx-1"></span>
              </template>
            </p>
            <div class="d-flex justify-content-start">
              <div v-if="mainNews.category" :style="'backgroundColor:' + mainNews.category.color"
                   class="badge badge-success p-2">{{ mainNews.category.name }}
              </div>
              <div class="mx-2">
                <p v-if="mainNews.source" class="news-card-source text-muted py-0 my-0">{{ mainNews.source.name }}</p>
                <p class="news-card-date text-muted py-0 my-0">{{ mainNews.date }}</p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="row">
      <template v-for="(newsItem,imageNewsIndex) in listNews">
        <div v-bind:class="{ 'col-3': !campaignMode , 'col-4': campaignMode, }" class="small-single-news-img">
          <news-card :without-desc="true" :item="newsItem"></news-card>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import NewsCard from "@/pages/visualEditors/homePage/NewsCard";

export default {
  name: 'category-classification-row-section-with-video',
  components: {NewsCard},
  props: {
    sectionData: {
      type: Object,
    },
    campaignMode: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    sectionData: {
      handler: function (newValue) {
        this.setSectionData();
      },
      deep: true
    }
  },
  mounted() {
    this.setSectionData();
  },
  data() {
    return {
      lang: null,
      sectionTitle: '',
      mainNews: {},
      listNews: [],
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    async setSectionData() {
      await this.resetSectionData();
      if (this.sectionData && this.sectionData.item) {
        this.sectionTitle = this.sectionData.item.name;
        let counter = 1;
        for (const news of this.sectionData.news) {
          if (counter !== 1) {
            if (this.listNews.length < 4) {
              if(this.campaignMode){
                if (this.listNews.length < 3) {
                  this.listNews.push(news);
                }
              }else{
                this.listNews.push(news);
              }

            }
          } else {
            this.mainNews = news;
          }
          counter++;
        }
      } else {
        await this.resetSectionData();
      }
    },
    async resetSectionData() {
      return new Promise(async (resolve, reject) => {
        this.sectionTitle = '';
        this.mainNews = {};
        this.listNews = [];
        return resolve();
      });
    }

  }

}
</script>
<style>
.section-container {
  justify-content: space-evenly;
}

.section-title {
  margin: 10px 50px;
  font-weight: bold;
}

.text-news {
  margin-top: 10px;
}

.image-news {
  margin-top: 20px;
}

.small-single-news-img .news-image {
  height: 150px;
}

.small-single-news-img .news-card-title {
  height: 40px;
  max-height: 40px;
}

.video-news-header {
  margin-bottom: 10px;
}
</style>
