<template>
  <div class="container">
    <h3 class="section-title">{{ sectionTitle }}</h3>
    <div class="row">
        <template v-for="(textNews,textNewsIndex) in listNews.slice(0, 2)">
          <div v-bind:class="{ 'col-6': campaignMode , 'col-4': !campaignMode, }" >
            <news-card  :item="textNews"></news-card>
          </div>
        </template>
    </div>
  </div>
</template>

<script>

import NewsCard from "@/pages/visualEditors/homePage/NewsCard";

export default {
  name: 'category-classification-row-section-two-news',
  components: {NewsCard},
  props: {
    sectionData: {
      type: Object,
    },
    campaignMode: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    sectionData: {
      handler: function (newValue) {
        this.setSectionData();
      },
      deep: true
    }
  },
  mounted() {
    this.setSectionData();
  },
  data() {
    return {
      lang: null,
      sectionTitle: '',
      listNews: [],
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    async setSectionData() {
      await this.resetSectionData();
      if (this.sectionData && this.sectionData.item) {
        this.sectionTitle = this.sectionData.item.name;
        this.listNews = this.sectionData.news;
      } else {
        await this.resetSectionData();
      }
    },
    async resetSectionData() {
      return new Promise(async (resolve, reject) => {
        this.sectionTitle = '';
        this.listNews = [];
        return resolve();
      });
    }

  }

}
</script>
<style>
.gallery-section-title{
  display: flex;
  justify-content: space-between;
}
.gallery-section-title .line-title{
    flex-grow: 2;
}
.gallery-section-title .line-title hr{
  border-color: #296404;
  border-width: 2px;
}

.news-two-columns-gallery {
  display: flex;
}
.news-two-columns-gallery img{
  width:100%;
}
.news-two-columns-gallery .gallery-column {
  display: flex;
  flex-flow: column;
  flex-basis: 49%;
}

.news-two-columns-gallery .gallery-column .gallery-news-top-item {
  position: relative;
}
.news-two-columns-gallery .gallery-column .gallery-news-top-item img{
  height: 450px;
}
.news-two-columns-gallery .gallery-column .gallery-news-top-item .text-flow{
    position: absolute;
    margin: 0px 20px;
    color: #ffffff;
    bottom: 1px;
}

.news-two-columns-gallery .gallery-column .gallery-news-top-item .text-flow .text-muted{
  color: #ffffff !important;
}
.news-two-columns-gallery .gallery-column .gallery-news-bottom{
  flex-basis: 28%;
  display: flex;
  justify-content: space-between;
}
.news-two-columns-gallery .gallery-column .gallery-news-bottom .gallery-news-bottom-item{
  flex-basis: 50%;
  padding: 1px;
  display:flex;
  flex-flow: column;
}
.news-two-columns-gallery .gallery-column .gallery-news-bottom .gallery-news-bottom-item .image-container{
  flex-basis: 80%;
}
.news-two-columns-gallery .gallery-column .gallery-news-bottom .gallery-news-bottom-item .image-container img{
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.news-two-columns-gallery .gallery-column .gallery-news-bottom .gallery-news-bottom-item .text-container{
  flex-basis: 20%;
  width: 90%;
}
</style>
