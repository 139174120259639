<template>
  <fieldset class="row scheduler-border">
    <legend class="scheduler-border">
      <i @click="openSelectImageModal()" class="fas fa-plus visual-editor-btn"></i>
    </legend>
    <div class="container my-4">
      <div class="row" style="margin: auto; justify-content: center">

        <template v-for="(cornerItem,index) in cornerItems">
          <div>
            <campaign-corner-panel-item :key="compKey"  v-if="cornerItem.type === 'PANEL'"
                                        :item="cornerItem.panel"></campaign-corner-panel-item>
            <campaign-corner-form-item :key="compKey"  v-if="cornerItem.type === 'FORM'"
                                        :item="cornerItem.form"></campaign-corner-form-item>
            <campaign-corner-poll-item :key="compKey"  v-if="cornerItem.type === 'POLL'"
                                       :item="cornerItem.poll"></campaign-corner-poll-item>
            <div class="mb-3 col-12">
              <l-button
                @click="removeCornerItem(index)"
                type="danger" size="sm">
                <i class="fa fa-trash"></i>
              </l-button>
            </div>
          </div>



        </template>

      </div>
    </div>
    <select-corner-item-modal
      :visible="selectPanelModalVisibility"
      @close="closeSelectImageModal"
      @saveData="saveData"
    >
    </select-corner-item-modal>
  </fieldset>
</template>

<script>


import SelectCornerItemModal from "@/pages/visualEditors/homePage/CampaignCorners/SelectCornerItemModal";
import CampaignCornerPanelItem from "@/pages/visualEditors/homePage/CampaignCorners/CampaignCornerPanelItem";
import CampaignCornerFormItem from "@/pages/visualEditors/homePage/CampaignCorners/CampaignCornerFormItem";
import CampaignCornerPollItem from "@/pages/visualEditors/homePage/CampaignCorners/CampaignCornerPolltem";

export default {
  name: 'campaign-corner',
  components: {
    CampaignCornerPollItem,
    CampaignCornerFormItem,
    CampaignCornerPanelItem,
    SelectCornerItemModal

  },
  props: {
    sectionData: {
      type: Array,
    },
    sectionKey: {
      type: String,
    },
  },
  watch: {
    sectionData: {
      handler: function (newValue) {
        this.setSectionBackendData();
      },
      deep: true
    }
  },
  mounted() {
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1);
  },
  data() {

    return {
      is_deleting_item: false,
      compKey: 0,
      cornerItems: [],
      sectionBackData: [],
      lang: null,
      selectPanelModalVisibility: false,
    }
  },

  methods: {
    setSectionBackendData() {
      this.compKey++;
      this.sectionBackData = [];
      this.cornerItems = this.sectionData.slice();
      for (let item of this.cornerItems) {
        let backItem = {
          type: item.type,
          panelId: null,
          pollId: null,
          form: null
        };
        if (item.panel && item.panel.id) {
          backItem.panelId = item.panel.id;
          this.sectionBackData.push(backItem);
        }
        if (item.poll && item.poll.id) {
          backItem.pollId = item.poll.id;
          this.sectionBackData.push(backItem);
        }
        if(item.form){
          backItem.form = item.form;
          this.sectionBackData.push(backItem);
        }
      }
      this.is_deleting_item = false;
    },
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    openSelectImageModal() {
      this.selectPanelModalVisibility = true;
    },
    closeSelectImageModal() {
      this.selectPanelModalVisibility = false;
    },
    removeCornerItem(index) {
      if(!this.is_deleting_item){
        this.is_deleting_item = true ;
        let cornerItems = this.cornerItems.slice();
        cornerItems.splice(index, 1);
        let sectionBackData = [];
        for (let item of cornerItems) {
          let backItem = {
            type: item.type,
            panelId: null,
            pollId: null,
            form: null
          };
          if (item.panel && item.panel.id) {
            backItem.panelId = item.panel.id;
            sectionBackData.push(backItem);
          }
          if (item.poll && item.poll.id) {
            backItem.pollId = item.poll.id;
            sectionBackData.push(backItem);
          }
          if(item.form){
            backItem.form = item.form;
            sectionBackData.push(backItem);
          }
        }
        let data = {
          'key': this.sectionKey,
          'value': sectionBackData,
          'lang': this.lang,
        }
        this.$emit('saveData', data);
      }
    },
    saveData(value) {
      this.sectionBackData.push(value);
      let data = {
        'key': this.sectionKey,
        'value': this.sectionBackData,
        'lang': this.lang,
      }
      this.$emit('saveData', data);
    }
  }

}
</script>
<style>

</style>
