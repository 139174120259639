<template>
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <i @click="openSelectNewsModal()" class="fas fa-pen visual-editor-btn"></i>
    </legend>
    <div class="container my-5 mx-2">
      <div class="row">
        <div v-bind:class="{ 'col-6': !campaignMode,'col-8': campaignMode, }">
          <div v-if="selectedNews[0]">
            <div class="d-flex align-items-start flex-column" style="height: 460px;">
              <div class=" w-100">
                <div class="px-1">
                  <video v-if="selectedNews[0].uploaded_video" controls class="card-img-top w-100 h-100">
                    <source :src="getUrl()+selectedNews[0].uploaded_video">
                    Your browser does not support the video tag.
                  </video>
                  <div v-else-if="selectedNews[0].embedded_video" v-html="selectedNews[0].embedded_video"  class="card-img-top embed-video w-100 h-100">

                  </div>
                  <img class="card-img-top w-100 h-100" v-else alt="this news must contain video">
                </div>
              </div>
              <p class="news-card-title mb-1">{{ selectedNews[0].title }}</p>
              <p class="news-card-excerpt text-muted">{{ selectedNews[0].excerpt }}</p>
              <p :style="'color:' + selectedNews[0].category.color" class="news-card-tag mt-auto">
              <template v-for="(item, index) in selectedNews[0].tags">
                <span>{{ item.name }}</span>
                <span v-if="index+1 !== selectedNews[0].tags.length" class="vertical-line mx-1"></span>
              </template>
              </p>
              <div class="d-flex justify-content-start">
                  <div :style="'backgroundColor:' + selectedNews[0].category.color" class="badge badge-success p-2">{{ selectedNews[0].category.name }}</div>
                <div class="mx-2">
                  <p class="news-card-source text-muted py-0 my-0">{{ selectedNews[0].source.name }}</p>
                  <p class="news-card-date text-muted py-0 my-0">{{ selectedNews[0].date }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-bind:class="{ 'col-3': !campaignMode,'col-4': campaignMode, }">
            <news-card v-if="selectedNews[1]" :item="selectedNews[1]"></news-card>
        </div>
        <div v-if="!campaignMode" class="col-md-3">
            <news-card v-if="selectedNews[2]" :item="selectedNews[2]"></news-card>
        </div>
      </div>
    </div>
    <select-news-modal
      :visible="selectNewsModalVisibility"
      :selectedNews="modalSelectedList"
      :selectLimit="selectedNewsLimit"
      @close="closeSelectNewsModal"
      @selectedNews="saveData"
    >
    </select-news-modal>
  </fieldset>
</template>

<script>
import SelectNewsModal from '@/pages/visualEditors/homePage/SelectNewsModal.vue';
import NewsCard from '@/pages/visualEditors/homePage/NewsCard.vue';

export default {
  name: 'second-section-row-4',
  components: {
    SelectNewsModal,
    NewsCard,
  },
  watch: {
    selectedNews: {
      handler: function (newValue) {
        this.setModalData();
      },
      deep: true
    }
  },
  props: {
    selectedNews: {
      type: Array,
    },
    campaignMode: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1);
  },
  data() {

    return {
      lang: null,
      modalSelectedList: [],
      selectNewsModalVisibility: false,
      selectedNewsLimit:3,
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    openSelectNewsModal() {
      this.selectNewsModalVisibility = true;
    },
    closeSelectNewsModal() {
      this.selectNewsModalVisibility = false;
    },
    setModalData() {
      if(this.campaignMode){
        this.selectedNewsLimit = 2;
      }
      this.selectedNews.map((element, index) => {
        this.modalSelectedList.push({id: element.id, title: element.title});
      })
    },
    saveData(value) {
      value.map((element, index) => {
        element.sort_order = index + 1;
      });
      let data = {
        'key': 'home_page_section_2_row_4',
        'value': value,
        'lang': this.lang,
      }
      this.$emit('saveData', data);
    }
  }

}
</script>
<style>
.vertical-line {
  border-left: 2px solid black;
  height: 25px;
  left: 50%;
}
.embed-video{
  overflow: hidden;
}
.embed-video iframe{
  width: 100% !important;
}
</style>
