<template>
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <i @click="openSelectCaseModal()" class="fas fa-pen visual-editor-btn"></i>
    </legend>
    <div  class="container my-4 ">
      <div v-if="sectionCase && Object.keys(sectionCase).length" class="case-section">
        <div  class="news-two-columns-gallery-case">
          <div class="gallery-column-case">
            <div class="section-title" v-if="sectionCase">
              <h3 class="">
              <span style="font-size:20px; margin:10px">
                <router-link v-if="caseId && $store.getters['auth/haveOneOfPermissions']([lang+'/cases/update'])"
                             v-tooltip.top-center="'Edit Case'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/cases/edit/'+caseId+'/'+lang">
                  <i class="fa fa-edit"></i>
                </router-link>
              </span>
                {{ caseText }}.   {{ sectionCase.title }}
              </h3>
              <h3 >{{ sectionCase.sub_title }}</h3>
              <p class="case-excerpt">{{ sectionCase.excerpt }}</p>
              <button class="follow-case-btn-live-coverage">{{ allNewsText }}</button>
              <div class="gallery-column-case">
                <div class="row news-details">
                  <div class="col-6" v-for="news in caseNews">
                    <p class="news-title-case">{{ news.title }}</p>
                    <p class="news-case-excerpt">{{ news.excerpt }}</p>
                    <div class="news-info">
                      <p>{{sourceText}} : {{ news.source.name }}</p>
                      <p>{{ news.date }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="gallery-column-case">
            <div class="case-files" v-if="sectionData.type">
              <div class="case-media" v-if="sectionData.type === 'image'" >
                <img class="media-item" v-if="sectionCase.image" :src="getUrl()+sectionCase.image"
                     alt="no image for this item">
                <img class="media-item" v-else alt="this case must contain image">
              </div>
              <div class="case-media" v-if="sectionData.type === 'video'">
                <video v-if="sectionCase.uploaded_video" controls class="media-item card-img-top w-100 h-100">
                  <source :src="getUrl()+sectionCase.uploaded_video">
                  Your browser does not support the video tag.
                </video>
                <div  v-else-if="sectionCase.embedded_video" v-html="sectionCase.embedded_video"  class="media-item card-img-top embed-video w-100 h-100">
                </div>
              </div>
              <div class="case-media" v-if="sectionData.type === 'gallery'">
                <div class= "case-gallery-container">
                  <div class="gallery-header-case">
                    <div class="gallery-header-case-two-img">
                      <div>
                        <img v-if="sectionCase.gallery[1] &&sectionCase.gallery[1].image" :src="getUrl()+sectionCase.gallery[1].image"
                             alt="no image for this item">
                        <img v-else alt="this case must contain gallery">
                      </div>
                      <div>

                        <img v-if="sectionCase.gallery[2] &&sectionCase.gallery[2].image" :src="getUrl()+sectionCase.gallery[2].image"
                             alt="no image for this item">
                        <img v-else alt="this case must contain gallery">
                      </div>

                    </div>

                    <div class="gallery-header-case-main-img">
                      <img v-if="sectionCase.gallery[0] &&sectionCase.gallery[0].image" :src="getUrl()+sectionCase.gallery[0].image"
                           alt="no image for this item">
                      <img v-else alt="this case must contain gallery">
                    </div>
                  </div>
                  <div class="gallery-header-case-bottom">
                    <div>
                      <img  :src="getUrl()+sectionCase.gallery[3].image"
                           v-if="sectionCase.gallery[3] &&sectionCase.gallery[3].image"
                           alt="no image for this item">
                      <img v-else alt="this case must contain gallery">
                    </div>

                    <div>
                      <img  :src="getUrl()+sectionCase.gallery[4].image"
                           v-if="sectionCase.gallery[4] &&sectionCase.gallery[4].image"
                           alt="no image for this item">
                      <img v-else alt="this case must contain gallery">
                    </div>

                    <div>
                      <img  :src="getUrl()+sectionCase.gallery[5].image"
                           v-if="sectionCase.gallery[5] &&sectionCase.gallery[5].image"
                           alt="no image for this item">
                      <img v-else alt="this case must contain gallery">
                    </div>

                  </div>
                </div>
              </div>

              <div class="case-icons">
                <i class="fas fa-camera"></i>
                <i class="fas fa-video"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <select-case-modal
      :visible="selectRowModalVisibility"
      :modalData="sectionData"
      @close="closeSelectRowModal"
      @saveData="saveData"
    >
    </select-case-modal>
  </fieldset>

</template>

<script>
import SelectRowModal from '@/pages/visualEditors/homePage/SelectRowModal.vue';
import CaseWithMainNewsRowSection from "@/pages/visualEditors/homePage/CaseWithMainNewsRowSection";
import CategoryClassificationRowSectionWithTwoColumns
  from "@/pages/visualEditors/homePage/CategoryClassificationRowSectionWithTwoColumns";
import CategoryClassificationRowSectionWithVideo
  from "@/pages/visualEditors/homePage/CategoryClassificationRowSectionWithVideo";
import CategoryClassificationRowSectionAsGallery
  from "@/pages/visualEditors/homePage/CategoryClassificationRowSectionAsGallery";
import CaseNewsRowSection from "@/pages/visualEditors/homePage/CaseNewsRowSection";
import CategoryClassificationRowSectionTwoNews
  from "@/pages/visualEditors/homePage/CategoryClassificationRowSectionTwoNews";
import SelectCaseModal from "./SelectCaseModal";

export default {
  name: 'live-coverage-header-section',
  components: {
    SelectCaseModal,
    CategoryClassificationRowSectionTwoNews,
    CaseNewsRowSection,
    CategoryClassificationRowSectionAsGallery,
    CategoryClassificationRowSectionWithVideo,
    CategoryClassificationRowSectionWithTwoColumns,
    CaseWithMainNewsRowSection,
  },
  watch: {
    sectionData: {
      handler: function (newValue) {
        this.setSectionData();
      },
      deep: true
    }
  },
  props: {
    sectionData: {
      type: Object,
    },
  },
  mounted() {
    this.setSectionData();
  },
  data() {

    return {
      lang: null,
      modalSelectedList: [],
      selectRowModalVisibility: false,
      caseText: "",
      sectionCase:{},
      sourceText: "",
      allNewsText: "",
      caseNews:[],
      caseId: null,
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    openSelectCaseModal() {
      this.selectRowModalVisibility = true;
    },
    closeSelectRowModal() {
      this.selectRowModalVisibility = false;
    },
    async setSectionData() {
      const path = window.location.href;
      this.lang = path.substring(path.lastIndexOf('/') + 1);
      switch (this.lang) {
        case 'ar':
          this.caseText = 'ملف';
          this.sourceText = 'المصدر';
          this.allNewsText = 'كل الاخبار';
          break;
        case 'en':
          this.caseText = 'case';
          this.sourceText = 'Source';
          this.allNewsText = 'All News';
          break;
        case 'fr':
          this.caseText = "Cas";
          this.sourceText = "La source";
          this.allNewsText = "Toutes les nouvelles";
          break;
        case 'es':
          this.caseText = 'Caso';
          this.sourceText = 'Fuente';
          this.allNewsText = 'Todas las noticias';
          break;
      }
      await this.resetSectionData();
      if (this.sectionData && this.sectionData.case) {
        this.caseId = this.sectionData.case.id;
        this.sectionCase = {...this.sectionData.case};
        this.caseNews = this.sectionCase.caseNews.slice(0,2);
      } else {
        await this.resetSectionData();
      }
    },
    saveData(value) {
      let data = {
        'key': 'live_coverage_header',
        'value': value,
        'lang': this.lang,
      }
      this.$emit('saveData', data);
    },
    async resetSectionData() {
      return new Promise(async (resolve, reject) => {
        this.caseId = null;
        this.sectionCase = {};
        this.caseNews = [];
        return resolve();
      });
    }
  }

}
</script>
<style>

.news-two-columns-gallery-case {
  display: flex;
}

.news-two-columns-gallery-case .gallery-column-case {
  display: flex;
  flex-flow: column;
  flex-basis: 49%;
}
.case-section{
  background-color: #333232;
  color: #FFFFFF;
}
.case-excerpt{
  font-size: 13px;
  max-height: 80px;
  overflow: hidden;
}
.news-details .news-title-case{
  font-size: 12px;
  font-weight: bold;
  max-height: 40px;
  overflow: hidden;
  margin-bottom: 10px;
}
.news-details .news-case-excerpt{
  font-size: 10px;
  font-weight: lighter;
  max-height: 50px;
  overflow: hidden;
}
.news-details .news-info{
  margin-top: 24px;
}
.news-details .news-info p{
  font-size: 10px;
  margin-bottom: 2px;
}
.case-files{
  display: flex;
  flex-flow: column;
  height: 100%;
}
.case-media{
  width: 100%;
}
.case-media .media-item{
  width: 100%;
  height: 350px !important;
  padding-top: 15px;
}

.case-icons{
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  width: 150px;
  flex-grow: 2;
  align-self: flex-end;
  align-items: flex-end;
  margin-bottom:  10px ;
}
.case-icons i{
  font-size: 50px;
}
.follow-case-btn-live-coverage {
  padding: 4px 40px;
  border-radius: 10px;
  background-color: inherit;
  color: #FFFFFF;
  border-color: #ffff;
  margin-bottom: 20px;
}

.case-gallery-container{
  overflow: hidden;
  height: 350px;
  padding-top: 15px;
}
.case-gallery-container  img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 1px;
}
.case-gallery-container .gallery-header-case{
  height: 70%;
  display: flex;
}
.case-gallery-container .gallery-header-case .gallery-header-case-two-img{
  flex-basis: 33%;
  display: flex;
  flex-flow: column;
}
.gallery-header-case-two-img div{
  flex-basis: 50%;
}
.case-gallery-container .gallery-header-case .gallery-header-case-main-img{
  flex-basis: 67%;
}
.case-gallery-container .gallery-header-case-bottom{
  height: 30%;
  display: flex;
}
.gallery-header-case-bottom div{
  flex-basis: 34%;
}

</style>
