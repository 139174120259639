<template>
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <i @click="openSelectImageModal()" class="fas fa-pen visual-editor-btn"></i>
    </legend>
    <div class="container my-4">
      <div class="row" style="margin: auto; min-height: 300px">
        <img style="width:100%; max-height: 600px" :src="getUrl()+sectionData" alt="You must set header image">
      </div>
    </div>
    <select-image-modal
      :visible="selectImageModalVisibility"
      :modalData="sectionData"
      @close="closeSelectImageModal"
      @saveData="saveData"
    >
    </select-image-modal>
  </fieldset>
</template>

<script>
import SelectImageModal from "@/pages/visualEditors/homePage/CampaignHeader/SelectImageModal";

export default {
  name: 'campaign-header',
  components: {
    SelectImageModal
  },
  props: {
    sectionData: {
      type: String,
    }
  },
  watch: {
    $route(to, from) {
      const path = window.location.href;
      this.lang = path.substring(path.lastIndexOf('/') + 1);
    }
  },
  mounted() {
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1);
  },
  data() {

    return {
      lang: null,
      selectImageModalVisibility: false,
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    openSelectImageModal(){
      this.selectImageModalVisibility = true;
    },
    closeSelectImageModal() {
      this.selectImageModalVisibility = false;
    },
    saveData(value) {
      let data = {
        'key': 'campaign_header',
        'value': value,
        'lang': this.lang,
      }
      this.$emit('saveData', data);
    }
  }

}
</script>
<style>

</style>
