<template>
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
     <i @click="openSelectNewsModal()" class="fas fa-pen visual-editor-btn"></i>
    </legend>
    <div class="container my-4">
      <div v-if="campaignMode" class="row">
        <first-section-campaign-mode :selected-news="selectedNews"></first-section-campaign-mode>
      </div>
      <div v-else class="row">
        <div class="col-md-6">
          <div class=" image-card-section-1  w-100 h-100" v-if="selectedNews[0]">
            <img v-if="selectedNews[0].featured_images && selectedNews[0].featured_images[0]" class="card-img-top"
                 :src="getUrl()+selectedNews[0].featured_images[0].image" alt="no image for this news">
            <div class="news-accessories">
              <span :style="'backgroundColor:' + selectedNews[0].category.color"
                    class="badge badge-success">{{ selectedNews[0].category.name }}</span>
              <p class="card-text news-title">
                {{ selectedNews[0].title }}
              </p>
              <span class="news-date">{{ selectedNews[0].date }}</span>
            </div>


          </div>
        </div>
        <div class="col-md-3">
          <div class="image-card-section-1 w-100 pb-1" v-if="selectedNews[1]">
            <img v-if="selectedNews[1].featured_images && selectedNews[1].featured_images[0]" class="card-img-top"
                 :src="getUrl()+selectedNews[1].featured_images[0].image" alt="no image for this news">
            <div class="news-accessories">
              <span :style="'backgroundColor:' + selectedNews[1].category.color"
                    class="badge badge-success">{{ selectedNews[1].category.name }}</span>
              <p class="card-text news-title">
                {{ selectedNews[1].title }}
              </p>
              <span class="news-date">{{ selectedNews[1].date }}</span>
            </div>
          </div>
          <div class="image-card-section-1  w-100" v-if="selectedNews[2]">
            <img v-if="selectedNews[2].featured_images && selectedNews[2].featured_images[0]" class="card-img-top"
                 :src="getUrl()+selectedNews[2].featured_images[0].image" alt="no image for this news">
            <div class="news-accessories">
              <span :style="'backgroundColor:' + selectedNews[2].category.color"
                    class="badge badge-success">{{ selectedNews[2].category.name }}</span>
              <p class="card-text news-title">
                {{ selectedNews[2].title }}
              </p>
              <span class="news-date">{{ selectedNews[2].date }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="image-card-section-1  w-100 pb-1" v-if="selectedNews[3]">
            <img v-if="selectedNews[3].featured_images && selectedNews[3].featured_images[0]" class="card-img-top"
                 :src="getUrl()+selectedNews[3].featured_images[0].image" alt="no image for this news">
            <div class="news-accessories">
              <span :style="'backgroundColor:' + selectedNews[3].category.color"
                    class="badge badge-success">{{ selectedNews[3].category.name }}</span>
              <p class="card-text news-title">
                {{ selectedNews[3].title }}
              </p>
              <span class="news-date">{{ selectedNews[3].date }}</span>
            </div>
          </div>
          <div class="image-card-section-1   w-100" v-if="selectedNews[4]">
            <img v-if="selectedNews[4].featured_images && selectedNews[4].featured_images[0]" class="card-img-top"
                 :src="getUrl()+selectedNews[4].featured_images[0].image" alt="no image for this news">
            <div class="news-accessories">
              <span :style="'backgroundColor:' + selectedNews[4].category.color"
                    class="badge badge-success">{{ selectedNews[4].category.name }}</span>
              <p class="card-text news-title">
                {{ selectedNews[4].title }}
              </p>
              <span class="news-date">{{ selectedNews[4].date }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <select-news-modal
      :visible="selectNewsModalVisibility"
      :selectedNews="modalSelectedList"
      :lang="lang"
      :selectLimit="selectedNewsLimit"
      @close="closeSelectNewsModal"
      @selectedNews="saveData"
    >

    </select-news-modal>
  </fieldset>
</template>

<script>
import SelectNewsModal from '@/pages/visualEditors/homePage/SelectNewsModal.vue';
import FirstSectionCampaignMode from "@/pages/visualEditors/homePage/firstSection/FirstSectionCampaignMode";

export default {
  name: 'first-section',
  components: {
    FirstSectionCampaignMode,
    SelectNewsModal
  },
  watch: {
    selectedNews: {
      handler: function (newValue) {
        this.setModalData();
      },
      deep: true
    }
  },
  props: {
    selectedNews: {
      type: Array,
    },
    campaignMode: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1);
    if(this.campaignMode){
      this.selectedNewsLimit = 6;
    }
  },
  data() {

    return {
      lang: null,
      modalSelectedList: [],
      selectedNewsLimit:6,
      selectNewsModalVisibility: false,
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    openSelectNewsModal() {
      this.selectNewsModalVisibility = true;
    },
    closeSelectNewsModal() {
      this.selectNewsModalVisibility = false;
    },
    setModalData() {
      this.selectedNews.map((element, index) => {
        this.modalSelectedList.push({id: element.id, title: element.title});
      })
      if(this.campaignMode){
        this.selectedNewsLimit = 6;
      }
    },
    saveData(value) {
      value.map((element, index) => {
        element.sort_order = index + 1;
      });
      let data = {
        'key': 'home_page_section_1',
        'value': value,
        'lang': this.lang,
      }
      this.$emit('saveData', data);
    }
  }

}
</script>
<style>

.image-card-section-1 {
  position: relative;
  width: 14rem;
  display: inline-block;
  height: 50%;
}

.image-card-section-1 img {
  width: 100%;
  height: 100%;
  max-height: 400px;
  object-fit: cover;
}

.news-accessories {
  position: absolute;
  bottom: 0;
  margin: 0px 10px 10px;
  color: white;
}

.news-title {
  overflow: hidden;
  margin: 5px 0px;
  max-height: 40px;
}

.news-date {
  color: #FFFFFF;
  font-size: 12px;
}

</style>
