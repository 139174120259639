<template>
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <i @click="openSelectRowModal()" class="fas fa-pen visual-editor-btn"></i>
    </legend>
    <div class="container my-4">
      <div class="row">
        <template v-if="sectionData && ['classification','category'].includes(sectionData.type)">
          <category-classification-row-section-with-two-columns :campaign-mode="campaignMode" v-if="viewType ==='two_columns'"
                                                                :section-data="sectionData"></category-classification-row-section-with-two-columns>
          <category-classification-row-section-with-video :campaign-mode="campaignMode" v-if="viewType ==='one_column_with_video'"
                                                          :section-data="sectionData"></category-classification-row-section-with-video>
          <category-classification-row-section-as-gallery :campaign-mode="campaignMode" v-if="viewType ==='as_gallery'"
                                                          :section-data="sectionData"></category-classification-row-section-as-gallery>

          <category-classification-row-section-two-news :campaign-mode="campaignMode" v-if="viewType ==='one_column_two_news'"
                                                          :section-data="sectionData"></category-classification-row-section-two-news>
        </template>
        <template v-if="sectionData && sectionData.type==='case'">
          <case-with-main-news-row-section :campaign-mode="campaignMode" v-if="viewType ==='case_with_main_news'"
                                           :section-data="sectionData"></case-with-main-news-row-section>
          <case-news-row-section :campaign-mode="campaignMode" v-if="viewType ==='case_news_list'"
                                 :section-data="sectionData"></case-news-row-section>
        </template>

      </div>
    </div>
    <select-row-modal
      :visible="selectRowModalVisibility"
      :selectedNews="modalSelectedList"
      :selectCategoryNewsLimit="newsLimit"
      :selectClassificationNewsLimit="newsLimit"
      :selectLimit="newsLimit"
      :modalData="sectionData"
      :typeList="typeList"
      :categories-data="categoriesData"
      :classification-data="classificationData"
      @close="closeSelectRowModal"
      @saveData="saveData"
    >
    </select-row-modal>
  </fieldset>
</template>

<script>
import SelectRowModal from '@/pages/visualEditors/homePage/SelectRowModal.vue';
import CaseWithMainNewsRowSection from "@/pages/visualEditors/homePage/CaseWithMainNewsRowSection";
import CategoryClassificationRowSectionWithTwoColumns
  from "@/pages/visualEditors/homePage/CategoryClassificationRowSectionWithTwoColumns";
import CategoryClassificationRowSectionWithVideo
  from "@/pages/visualEditors/homePage/CategoryClassificationRowSectionWithVideo";
import CategoryClassificationRowSectionAsGallery
  from "@/pages/visualEditors/homePage/CategoryClassificationRowSectionAsGallery";
import CaseNewsRowSection from "@/pages/visualEditors/homePage/CaseNewsRowSection";
import CategoryClassificationRowSectionTwoNews
  from "@/pages/visualEditors/homePage/CategoryClassificationRowSectionTwoNews";

export default {
  name: 'row-section',
  components: {
    CategoryClassificationRowSectionTwoNews,
    CaseNewsRowSection,
    CategoryClassificationRowSectionAsGallery,
    CategoryClassificationRowSectionWithVideo,
    CategoryClassificationRowSectionWithTwoColumns,
    CaseWithMainNewsRowSection,
    SelectRowModal
  },
  watch: {
    sectionData: {
      handler: function (newValue) {
        this.setSectionData();
      },
      deep: true
    }
  },
  props: {
    typeList: {
      type: Array,
    },
    sectionData: {
      type: Object,
    },
    viewType: {},
    newsLimit: {},
    sectionKey: {
      type: String,
    },
    classificationData: {
      type: Array
    },
    categoriesData: {
      type: Array
    },
    campaignMode: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1);
  },
  data() {

    return {
      lang: null,
      modalSelectedList: [],
      selectRowModalVisibility: false,
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    openSelectRowModal() {
      this.selectRowModalVisibility = true;
    },
    closeSelectRowModal() {
      this.selectRowModalVisibility = false;
    },
    setSectionData() {

    },
    saveData(value) {
      let data = {
        'key': this.sectionKey,
        'value': value,
        'lang': this.lang,
      }
      this.$emit('saveData', data);
    }
  }

}
</script>
<style>

.image-card-section-1 {
  position: relative;
  width: 14rem;
  display: inline-block;
  height: 50%;
}

.image-card-section-1 img {
  width: 100%;
  height: 100%;
  max-height: 400px;
  object-fit: cover;
}

.news-accessories {
  position: absolute;
  bottom: 0;
  margin: 0px 10px 10px;
  color: white;
}

.news-title {
  overflow: hidden;
  margin: 5px 0px;
  max-height: 40px;
}

.news-date {
  color: #FFFFFF;
  font-size: 12px;
}

</style>
