<template>

  <div class="menu-container">
    <draggable v-if="items" v-model="items" group="news" @start="drag=true" @end="moveEventFunction">
      <template v-for="(item,index) in items">
        <menu-item-render  @addSubItem="addSubItem"
                           @deleteItem="deleteItem"
                           @editSubItem="editSubItem"
                           :key="item.sort_order +'_' +index"
                           :index="index"
                           :item="item"
                           :categories="categories"
                           :pages="pages"
                           :classifications="classifications">

        </menu-item-render>
      </template>
    </draggable>

  </div>
</template>
<script>

import MenuItemRender from "@/pages/visualEditors/menus/MenuItemRender";
import draggable from 'vuedraggable';
export default {
  name: 'menu-render',
  components: {draggable,MenuItemRender},
  props: {
    value:{},
    pages: {
      type: Array,
    },
    categories: {
      type: Array,
    },
    classifications: {
      type: Array,
    },
  },
  data() {
    return {
      items:this.value
    };
  },
  mounted() {
  },
  methods: {
    moveEventFunction() {
      let value = this.items.slice();
      value.map((element, index) => {
        element.sort_order = index
      });
      this.$emit("input", value);
    },
    deleteItem(index){
      let value = this.items.slice();
      value.splice(index,1);
      this.$emit("input", value);
    },
    addSubItem(item){
      let addedItem = {...item};
      delete addedItem['index'];
      let value = this.items.slice();
      value[item.index].items.push(addedItem);
      this.$emit("input", value);
    },
    editSubItem(item){
      let editedItem = {...item};
      delete editedItem['index'];
      let value = this.items.slice();
      value[item.index] = {...item};
      this.$emit("input", value);
    }
  }
}
</script>

<style>

</style>
