<template>
  <div class="container">
    <div class="gallery-section-title">
      <div class="line-title"><hr></div>
      <div><h3  class="section-title">{{ sectionTitle }}</h3></div>
      <div class="line-title"><hr></div>
    </div>

    <div class="news-two-columns-gallery">
      <div class="gallery-column">
        <div class="gallery-news-top-item">
          <img v-if="mainNews1.featured_images && mainNews1.featured_images[0]"
               :src="getUrl()+mainNews1.featured_images[0].image"
               alt="no image for this news">
          <img v-else alt="This News Must Contain Image">
          <div class="text-flow">
            <p class="news-card-title mb-1">{{mainNews1.title}}</p>
            <p class="news-card-date text-muted">{{mainNews1.date}}</p>
          </div>
        </div>
        <div class="gallery-news-bottom">
          <template v-for="(newsItem,imageNewsIndex) in listNews1">
            <div class="gallery-news-bottom-item">
              <div class="image-container">
                <img v-if="newsItem.featured_images && newsItem.featured_images[0]"
                     :src="getUrl()+newsItem.featured_images[0].image"
                     alt="no image for this news">
                <img v-else alt="This News Must Contain Image">
              </div>
              <div class="text-container">
                <p class="news-card-title mb-1">{{newsItem.title}}</p>
                <p class="news-card-date text-muted">{{newsItem.date}}</p>
              </div>

            </div>
          </template>
        </div>
      </div>
      <div class="gallery-column">
        <div class="gallery-news-top-item">
          <img v-if="mainNews2.featured_images && mainNews2.featured_images[0]"
               :src="getUrl()+mainNews2.featured_images[0].image"
               alt="no image for this news">
          <img v-else alt="This News Must Contain Image">
          <div class="text-flow">
            <p class="news-card-title mb-1">{{mainNews2.title}}</p>
            <p class="news-card-date text-muted">{{mainNews2.date}}</p>
          </div>
        </div>
        <div class="gallery-news-bottom">
          <template v-for="(newsItem,imageNewsIndex) in listNews2">
            <div class="gallery-news-bottom-item">
              <div class="image-container">
                <img v-if="newsItem.featured_images && newsItem.featured_images[0]"
                     :src="getUrl()+newsItem.featured_images[0].image"
                     alt="no image for this news">
                <img v-else alt="This News Must Contain Image">
              </div>
              <div class="text-container">
                <p class="news-card-title mb-1">{{newsItem.title}}</p>
                <p class="news-card-date text-muted">{{newsItem.date}}</p>
              </div>

            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import NewsCard from "@/pages/visualEditors/homePage/NewsCard";

export default {
  name: 'category-classification-row-section-as-gallery',
  components: {NewsCard},
  props: {
    sectionData: {
      type: Object,
    },
    campaignMode: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    sectionData: {
      handler: function (newValue) {
        this.setSectionData();
      },
      deep: true
    }
  },
  mounted() {
    this.setSectionData();
  },
  data() {
    return {
      lang: null,
      sectionTitle: '',
      mainNews1: {},
      mainNews2: {},
      listNews1: [],
      listNews2: [],
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    async setSectionData() {
      await this.resetSectionData();
      if (this.sectionData && this.sectionData.item) {
        this.sectionTitle = this.sectionData.item.name;
        let counter = 1;
        for (const news of this.sectionData.news) {
          if (counter === 1) {
            this.mainNews1 = news;
          } else {
            if (counter === 2) {
              this.mainNews2 = news;
            } else {
              if (this.listNews1.length < 2) {
                this.listNews1.push(news);
              } else {
                if (this.listNews2.length < 2) {
                  this.listNews2.push(news);
                }
              }
            }
          }

          counter++;
        }
      } else {
        await this.resetSectionData();
      }
    },
    async resetSectionData() {
      return new Promise(async (resolve, reject) => {
        this.sectionTitle = '';
        this.mainNews1 = {};
        this.mainNews2 = {};
        this.listNews1 = [];
        this.listNews2 = [];
        return resolve();
      });
    }

  }

}
</script>
<style>
.gallery-section-title{
  display: flex;
  justify-content: space-between;
}
.gallery-section-title .line-title{
    flex-grow: 2;
}
.gallery-section-title .line-title hr{
  border-color: #296404;
  border-width: 2px;
}

.news-two-columns-gallery {
  display: flex;
}
.news-two-columns-gallery img{
  width:100%;
}
.news-two-columns-gallery .gallery-column {
  display: flex;
  flex-flow: column;
  flex-basis: 49%;
}

.news-two-columns-gallery .gallery-column .gallery-news-top-item {
  position: relative;
}
.news-two-columns-gallery .gallery-column .gallery-news-top-item img{
  height: 450px;
}
.news-two-columns-gallery .gallery-column .gallery-news-top-item .text-flow{
    position: absolute;
    margin: 0px 20px;
    color: #ffffff;
    bottom: 1px;
}

.news-two-columns-gallery .gallery-column .gallery-news-top-item .text-flow .text-muted{
  color: #ffffff !important;
}
.news-two-columns-gallery .gallery-column .gallery-news-bottom{
  flex-basis: 28%;
  display: flex;
  justify-content: space-between;
}
.news-two-columns-gallery .gallery-column .gallery-news-bottom .gallery-news-bottom-item{
  flex-basis: 50%;
  padding: 1px;
  display:flex;
  flex-flow: column;
}
.news-two-columns-gallery .gallery-column .gallery-news-bottom .gallery-news-bottom-item .image-container{
  flex-basis: 80%;
}
.news-two-columns-gallery .gallery-column .gallery-news-bottom .gallery-news-bottom-item .image-container img{
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.news-two-columns-gallery .gallery-column .gallery-news-bottom .gallery-news-bottom-item .text-container{
  flex-basis: 20%;
  width: 90%;
}
</style>
