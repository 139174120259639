<template>
  <el-dialog
    center
    @close="closeModal()"
    :visible="visible"
    dir="ltr">
    <div class="col-12">
       <h6>live coverage :  </h6>
    </div>
    <div class="col-12">
      <fg-select
        name="column_type"
        size="large"
        filterable
        clearable
        placeholder="Type"
        :input-classes="'select-default'"
        :label="'Type'"
        :list="typeList"
        :listItemLabel="'name'"
        :listItemValue="'name'"
        v-model="selectedType"
      >
      </fg-select>
    </div>
    <div class="col-md-12" v-if="selectedType !=='none'">
      <paginated-select
        :labelTitle="'Choose Case'"
        :isMultiple="false"
        @select-changed="setSelectedCase"
        :apiUrl="'cases/paginatedCases'"
        :initData="selectedCase"
        :lang="lang"
        :key="paginatedCompKey"
      >
      </paginated-select>
    </div>


    <div slot="footer" class="dialog-footer">
      <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
      <button class="btn btn-info" @click="confirmModal()">Confirm</button>
    </div>
  </el-dialog>
</template>

<script>
import {Dialog, Option, Select} from 'element-ui'
import draggable from 'vuedraggable';
import PaginatedSelect from "@/components/paginatedSelect";
import FgSelect from "../../../components/Inputs/formGroupSelect";

export default {
  name: 'select-case-modal',
  components: {
    FgSelect,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    draggable,
    PaginatedSelect
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    },
    modalData: {
      type: Object,
    },
  },
  watch: {
    modalData: {
      handler: function (newValue) {
        this.setModalData();
      },
      deep: true
    }
  },
  data() {

    return {
      paginatedCompKey: 0,
      selectedCase: null,
      case: {},
      caseType: null,
      lang: null,
      typeList: [{name: 'image'}, {name: 'video'}, {name: 'gallery'},{name: 'none'}],
      selectedType:null,
    }
  },
  mounted() {
    this.setModalData();
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    confirmModal() {
      if(this.selectedType === 'none'){
        this.selectedItemId = null ;
        this.selectedCase = null;
      }
      let modalData = {
        type: this.selectedType,
        case: this.selectedItemId
      }

      this.$emit('saveData', modalData);
      this.closeModal();
    },
    setModalData() {
      const path = window.location.href;
      this.lang = path.substring(path.lastIndexOf('/') + 1);
      if(this.modalData){
        this.selectedType = this.modalData.type;
        if(this.modalData.case){
          this.selectedItemId = this.modalData.case.id;
          this.selectedCase = {
            id:this.modalData.case.id,
            title:this.modalData.case.title
          };
        }
      }
    },
    setSelectedCase(selectedCase){
      this.selectedItemId = selectedCase.id;
    },
  }

}
</script>

