<template>
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <i @click="openSelectNewsModal()" class="fas fa-pen visual-editor-btn"></i>
    </legend>
    <div style="height: 100%" class="container my-4">
      <div style="height: 100%" class="row">
        <div v-bind:class="{ 'col-6': !campaignMode , 'col-7': campaignMode, }" v-if="selectedNews[0]">
          <div class="container">
            <div class="row ">
              <div class="col-md-8 px-1">
                <img class="card-img-top img- w-100 h-100" style="max-height: 300px"
                     v-if="selectedNews[0].gallery &&selectedNews[0].gallery[0]"
                     :src="getUrl()+selectedNews[0].gallery[0].image" alt="no image for this news">
                <img v-else alt="this news must contain gallery">
              </div>
              <div class="col-md-4 px-1">
                <img class="card-img-top pb-1" style="height: 50%"
                     v-if="selectedNews[0].gallery &&selectedNews[0].gallery[1]"
                     :src="getUrl()+selectedNews[0].gallery[1].image" alt="no image for this news">
                <img v-else alt="this news must contain More gallery Image">
                <img class="card-img-top pt-1" style="height: 50%"
                     v-if="selectedNews[0].gallery &&selectedNews[0].gallery[2]"
                     :src="getUrl()+selectedNews[0].gallery[2].image" alt="no image for this news">
                <img v-else alt="this news must contain More gallery Image">
              </div>
              <div class="px-1 align-items-start flex-column">
                <p class="section-2-title-text mb-1">{{ selectedNews[0].title }}</p>
                <p class="section-2-excerpt-text text-muted">{{ selectedNews[0].excerpt }}</p>
                <p :style="'color:' + selectedNews[0].category.color" class="news-card-tag mt-auto">
                  <template v-for="(item, index) in selectedNews[0].tags">
                    <span>{{ item.name }}</span>
                    <span v-if="index+1 !== selectedNews[0].tags.length" class="vertical-line mx-1"></span>
                  </template>
                </p>
                <div class="d-flex justify-content-start ">
                  <div>
                    <div :style="'backgroundColor:' + selectedNews[0].category.color" class="badge badge-success p-2">
                      {{ selectedNews[0].category.name }}
                    </div>
                  </div>
                  <div class="mx-2">
                    <p class="section-2-date-author-text text-muted py-0 my-0">{{ selectedNews[0].source.name }}</p>
                    <p class="section-2-date-author-text text-muted py-0 my-0">{{ selectedNews[0].date }}</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div v-bind:class="{ 'col-4': !campaignMode,'col-5': campaignMode, }"  v-if="selectedNews[1]">
          <news-card :item="selectedNews[1]"></news-card>
        </div>
      </div>
    </div>
    <select-news-modal :visible="selectNewsModalVisibility"
                       :selectedNews="modalSelectedList"
                       :selectLimit="2"
                       @close="closeSelectNewsModal"
                       @selectedNews="saveData"
    >
    </select-news-modal>
  </fieldset>
</template>

<script>
import NewsCard from '@/pages/visualEditors/homePage/NewsCard.vue';
import SelectNewsModal from '@/pages/visualEditors/homePage/SelectNewsModal.vue';

export default {
  name: 'second-section-row-1',
  components: {
    SelectNewsModal,
    NewsCard
  },
  watch: {
    selectedNews: {
      handler: function (newValue) {
        this.setModalData();
      },
      deep: true
    }
  },
  props: {
    selectedNews: {
      type: Array,
    },
    campaignMode: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1);
  },
  data() {

    return {
      lang: null,
      modalSelectedList: [],
      selectNewsModalVisibility: false,
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    openSelectNewsModal() {
      this.selectNewsModalVisibility = true;
    },
    closeSelectNewsModal() {
      this.selectNewsModalVisibility = false;
    },
    setModalData() {
      this.selectedNews.map((element, index) => {
        this.modalSelectedList.push({id: element.id, title: element.title});
      })
    },
    saveData(value) {
      value.map((element, index) => {
        element.sort_order = index + 1;
      });
      let data = {
        'key': 'home_page_section_2_row_1',
        'value': value,
        'lang': this.lang,
      }
      this.$emit('saveData', data);
    }
  }

}
</script>

