<template>

  <div>
    <div class="menu-item">
      <div class="text-section">
        <template v-if="item && item.label">{{ item.label }}</template>
        <template v-else>
          <template v-if="item && item.type ==='page'">
            {{ item.item.page_name }}
          </template>
          <template v-if="item &&['classification','category','page'].includes(item.type)">
            {{ item.item.name }}
          </template>
        </template>
        <template
          v-if="item.haveSubMenu && ['category','classification'].includes(item.type) && item.subMenuStrategy ==='last_news'">
          <span class="badge-info badge">last news will shown as sub menu</span>
        </template>
      </div>
      <div class="actions-section">
        <a
          v-if="(item.haveSubMenu && !['category','classification'].includes(item.type))
|| (item.haveSubMenu && ['category','classification'].includes(item.type) && item.subMenuStrategy ==='selected_items')"
          v-tooltip.top-center="'Add Sub Item'"
          class="btn-warning btn-simple btn-link"
          @click="openNewItemModal()">
          <i class="fa fa-plus"></i></a>
        <a
          v-tooltip.top-center="'Edit'"
          class="btn-danger btn-simple btn-link"
          @click="openEditItemModal()">
          <i class="fa fa-edit"></i></a>
        <a
          v-tooltip.top-center="'Delete'"
          class="btn-danger btn-simple btn-link"
          @click="deleteMenuItem()">
          <i class="fa fa-times"></i></a>
      </div>
    </div>
    <div v-if="item.items && item.items.length" class="">
      <menu-render style="margin-left: 50px"
                   v-model="item.items"
                   :key="'child' + item.sort_order"
                   :categories="categories"
                   :pages="pages"
                   :classifications="classifications">
      </menu-render>
    </div>
    <add-new-menu-item-modal
      :visible="newItemModalVisibility"
      :categories="categories"
      :pages="pages"
      :classifications="classifications"
      @close="closeNewItemModal"
      @addItem="saveNewItem"
    >
    </add-new-menu-item-modal>
    <edit-menu-item-modal
      :visible="editItemModalVisibility"
      :categories="categories"
      :pages="pages"
      :classifications="classifications"
      :modal-data="item"
      @close="closeEditItemModal"
      @saveItem="saveEditItem"
    >
    </edit-menu-item-modal>
  </div>
</template>
<script>

import AddNewMenuItemModal from "@/pages/visualEditors/menus/AddNewMenuItemModal";
import MenuRender from "./MenuRender";
import EditMenuItemModal from "@/pages/visualEditors/menus/EditMenuItemModal";

export default {
  name: 'menu-item-render',
  components: {EditMenuItemModal, MenuRender, AddNewMenuItemModal},
  props: {
    item: {
      type: Object,
    },
    index: {
      type: Number
    },
    pages: {
      type: Array,
    },
    categories: {
      type: Array,
    },
    classifications: {
      type: Array,
    },
  },
  beforeCreate() {
    if (this.$options.components)
      this.$options.components.MenuRender = require('./MenuRender.vue').default
  },
  data() {
    return {
      newItemModalVisibility: false,
      editItemModalVisibility: false,
    };
  },
  mounted() {
  },
  methods: {
    deleteMenuItem() {
      this.$emit('deleteItem', this.index);
    },
    openNewItemModal() {
      this.newItemModalVisibility = true;
    },
    closeNewItemModal() {
      this.newItemModalVisibility = false;
    },
    saveNewItem(item) {
      item.sort_order = this.item.items.length + 1;
      item.index = this.index;
      this.$emit('addSubItem', item);
    },
    openEditItemModal() {
      this.editItemModalVisibility = true;
    },
    closeEditItemModal() {
      this.editItemModalVisibility = false;
    },
    saveEditItem(item) {
      item.sort_order = this.item.sort_order;
      if (item.haveSubMenu) {
        if (!item.subMenuStrategy ||(item.subMenuStrategy && item.subMenuStrategy === 'selected_items')) {
          item.items = this.item.items;
        }
      }
      item.index = this.index;
      this.$emit('editSubItem', item);
    },
  }
}
</script>

<style>
.menu-item {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid;
  display: flex;
  justify-content: space-between;
}
</style>
