<template>
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <i @click="openSelectNewsModal()" class="fas fa-pen visual-editor-btn"></i>
    </legend>
    <div class="container my-5">
      <div class="row">
        <div v-bind:class="{ 'col-3': !campaignMode,'col-4': campaignMode, }">
          <news-card v-if="selectedNews[0]" :item="selectedNews[0]"></news-card>
        </div>
        <div v-if="!campaignMode" class="col-md-3">
          <news-card v-if="selectedNews[1]" :item="selectedNews[1]"></news-card>
        </div>
        <div v-bind:class="{ 'col-6': !campaignMode,'col-8': campaignMode, }">
          <div style="height: 100%" v-if="mainNews && mainNews.id" class="d-flex align-items-start flex-column">
            <div class="container">
              <div class="row">
                <div class="col-md-7 px-1">
                  <img v-if="mainNews.gallery && mainNews.gallery[0]"
                       style="min-height: 275px" class="card-img-top w-100 h-100"
                       :src="getUrl()+mainNews.gallery[0].image"
                       alt="no image for this news">
                  <img v-else alt="this news must contain gallery ">
                </div>
                <div class="col-md-5 px-1">
                  <img style="min-height: 275px" class="card-img-top w-100 h-100"
                       v-if="mainNews.gallery && mainNews.gallery[1]"
                       :src="getUrl()+mainNews.gallery[1].image" alt="no image for this news">
                  <img v-else alt="this news must contain more gallery image">
                </div>
              </div>
            </div>

            <p class="news-card-title mb-1">{{ mainNews.title }}</p>
            <p class="news-card-excerpt text-muted">{{ mainNews.excerpt }}</p>
            <p :style="'color:' + mainNews.category.color" class="news-card-tag mt-auto">
              <template v-for="(item, index) in mainNews.tags">
                <span>{{ item.name }}</span>
                <span v-if="index+1 !== mainNews.tags.length" class="vertical-line mx-1"></span>
              </template>
            </p>
            <div class="d-flex justify-content-start ">
              <div :style="'backgroundColor:' + mainNews.category.color" class="badge badge-success p-2">
                {{ mainNews.category.name }}
              </div>
              <div class="mx-2">
                <p class="news-card-source text-muted py-0 my-0">{{ mainNews.source.name }}</p>
                <p class="news-card-date text-muted py-0 my-0">{{ mainNews.date }}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <select-news-modal
      :visible="selectNewsModalVisibility"
      :selectedNews="modalSelectedList"
      :selectLimit="selectedNewsLimit"
      @close="closeSelectNewsModal"
      @selectedNews="saveData"
    >
    </select-news-modal>
  </fieldset>
</template>

<script>
import SelectNewsModal from '@/pages/visualEditors/homePage/SelectNewsModal.vue';
import NewsCard from '@/pages/visualEditors/homePage/NewsCard.vue';

export default {
  name: 'second-section-row-3',
  components: {
    SelectNewsModal,
    NewsCard,
  },
  watch: {
    selectedNews: {
      handler: function (newValue) {
        this.setModalData();
      },
      deep: true
    }
  },
  props: {
    selectedNews: {
      type: Array,
    },
    campaignMode: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1);
  },
  data() {

    return {
      lang: null,
      modalSelectedList: [],
      selectNewsModalVisibility: false,
      selectedNewsLimit:3,
      mainNews:{},
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    openSelectNewsModal() {
      this.selectNewsModalVisibility = true;
    },
    closeSelectNewsModal() {
      this.selectNewsModalVisibility = false;
    },
    setModalData() {

      if(this.campaignMode){
        this.selectedNewsLimit = 2;
      }
      if(this.campaignMode){
        if(this.selectedNews[1]){
          this.mainNews = this.selectedNews[1];
        }
      }else{
        if(this.selectedNews[2]){
          this.mainNews = this.selectedNews[2];
        }
      }
      this.selectedNews.map((element, index) => {
        this.modalSelectedList.push({id: element.id, title: element.title});
      })
    },
    saveData(value) {
      value.map((element, index) => {
        element.sort_order = index + 1;
      });
      let data = {
        'key': 'home_page_section_2_row_3',
        'value': value,
        'lang': this.lang,
      }
      this.$emit('saveData', data);
    }
  }

}
</script>
<style>

</style>
