<template>
  <el-dialog
    center
    @close="closeModal()"
    :visible="visible"
    dir="ltr">
    <div class="col-md-12">
      <paginated-select
        :labelTitle="'Choose News'"
        :isMultiple="true"
        @select-changed="setSelectedNews"
        :apiUrl="lang +'/news/paginatedNews'"
        :initData="draggableNews"
        :selectLimiter="selectLimit"
        :key="paginatedCompKey"
      >
      </paginated-select>
      <draggable class="container" v-model="draggableNews" group="news" @start="drag=true" @end="moveEventFunction">
        <div v-for="(item, x) in draggableNews" :key="'Item_'+x">
          <card class="mt-2 mb-0">
            <span class="badge badge-success mx-2">{{ x + 1 }}</span>
            <span>{{ item.title }}</span>
          </card>
        </div>
      </draggable>
    </div>


    <div slot="footer" class="dialog-footer">
      <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
      <button class="btn btn-info" @click="confirmModal()">Confirm</button>
    </div>
  </el-dialog>
</template>

<script>
import {Dialog, Option, Select} from 'element-ui'
import draggable from 'vuedraggable';
import PaginatedSelect from "@/components/paginatedSelect";

export default {
  name: 'select-news-modal',
  components: {
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    draggable,
    PaginatedSelect
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    },
    selectLimit: {
      type: Number
    },
    selectedNews: {
      type: Array,
    },
  },
  data() {

    return {
      paginatedCompKey: 0,
      draggableNews: this.selectedNews,
      newsList: [],
      lang: null,
    }
  },
  mounted() {
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1);
    this.draggableNews = this.selectedNews;
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    confirmModal() {
      this.$emit('selectedNews', this.draggableNews);
      this.closeModal();
    },
    moveEventFunction() {
      // this.news.map((element, index) => { element.sort_order =  index})
    },
    setSelectedNews(selectedNews) {
      this.draggableNews = selectedNews;
    }
  }

}
</script>

