<template>
  <div class="row">
    <div class="col-6">
      <div class="d-flex  align-items-start flex-column">
        <p class="news-card-title mb-1">{{ item.title }}</p>
        <p class="news-horizontal-card-excerpt text-muted">{{ item.excerpt }}</p>
        <p :style="'color:' + item.category.color" class="news-card-tag mt-auto">
          <template v-for="(ele, index) in item.tags">
            <span>{{ ele.name }}</span>
            <span v-if="item.tags && index+1 !== item.tags.length" class="vertical-line mx-1"></span>
          </template>
        </p>
        <div class="d-flex justify-content-start">
          <div :style="'backgroundColor:' + item.category.color" class="badge badge-success p-2">
            {{ item.category.name }}
          </div>
          <div class="mx-2">
            <p class="news-card-source text-muted py-0 my-0">{{ item.source.name }}</p>
            <p class="news-card-date text-muted py-0 my-0">{{ item.date }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <img class="news-horizontal-image" v-if="item.featured_images && item.featured_images[0]"
           :src="getUrl()+item.featured_images[0].image"
           alt="no image for this news">
    </div>
  </div>

</template>

<script>


export default {
  name: 'news-horizontal-card',
  components: {},
  props: ['item'],
  data() {
    return {}
  },

  computed: {},

  mounted() {
  },
  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
  }

}
</script>
<style>

.news-card-title {
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  max-height: 40px;
}

.news-horizontal-card-excerpt {
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  margin: 5px 0px;
  max-height: 60px;
}

.news-card-source, .news-card-date {
  font-size: 10px;
}
.news-horizontal-image{
  width: 100%;
  object-fit: cover;
  height: 100%;
}
</style>
