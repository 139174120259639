<template>
  <div class="campaign-corner-item col-12" v-if="form">
    <h6>{{form }} form will show here</h6>
  </div>
</template>

<script>


export default {
  name: 'campaign-corner-form-item',
  props: {
    item: {
      type: String,
    },
  },
  mounted() {
    this.form = this.item;
  },
  data() {
    return {
      form: null,
    }
  },
  methods: {}

}
</script>
<style>
.campaign-btn{
  padding: 5px;
  border: 2px solid #397908;
  border-radius: 5px;
  color: #0e0e0e;
}
.campaign-corner-item{
  margin-bottom: 15px;
}

</style>
