<template>
  <div class="panel-corner-item col-12" v-if="panel">
    <h6>{{ panel.main_heading }}</h6>
    <template v-if="panel.image">
        <img class="campaign-image" :src="getUrl() +panel.image">
    </template>
    <template v-if="!panel.image">
      <div class="panel-corner-item-desc" v-html="panel.content"></div>
      <div  v-if="panel.destination_url">
        <a class="campaign-btn" :href="panel.destination_url">{{panel.destination_url_title?panel.destination_url_title:'read more'}}</a>
      </div>
    </template>

  </div>
</template>

<script>


export default {
  name: 'campaign-corner-panel-item',
  props: {
    item: {
      type: Object,
    },
  },
  mounted() {
    this.panel = this.item;
  },
  data() {
    return {
      panel: null,
    }
  },
  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
  }

}
</script>
<style>
.campaign-btn{
  padding: 5px;
  border: 2px solid #397908;
  border-radius: 5px;
  color: #0e0e0e;
}
.panel-corner-item{
  margin-bottom: 15px;
}
.panel-corner-item-desc , .panel-corner-item-desc *{
  font-size:10px !important;
}
.campaign-image{
  width: 100%;
}
</style>
