<template>
  <div>
    <div ref="groupForm" class="row">
      <div class="col-12">
        <card>
          <div class="row">
            <div class="col-6">
              <label class="card-label">Start Date</label>
              <fg-input v-model="formData.start_date"
                        placeholder="Chose panel publish date"
                        type="datetime-local"
                        value-format="YYYY-MM-DD HH:MM:SS"
              >
              </fg-input>
            </div>
            <div class="col-6">
              <label class="card-label">End Date</label>
              <fg-input v-model="formData.end_date"
                        placeholder="Chose panel expire date"
                        type="datetime-local"
                        value-format="YYYY-MM-DD HH:MM:SS"
              >

              </fg-input>
            </div>
          </div>
          <div class="row mt-2">
            <div class="form-group col-6 pl-6">
              <label>Is Active</label>&nbsp;
              <l-switch v-model="formData.is_active">
                <i slot="on" class="fa fa-check"></i>
                <i slot="off" class="fa fa-times"></i>
              </l-switch>
            </div>
            <div class="col-6">
              <button
                @click="saveSectionPublish"
                class="ml-2 btn btn btn-wd btn-info "> Save
              </button>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div ref="groupForm" class="row" :class="dir!=='rtl'?'null':'rtl-class'">
      <div class="col-12">
        <live-coverage-header-section :sectionData="liveCoverageHeaderData"
                                      @saveData="saveSectionData"></live-coverage-header-section>
      </div>
    </div>
  </div>
</template>
<script>
import LiveCoverageHeaderSection from "@/pages/visualEditors/homePage/liveCoverageHeaderSection";
import {Switch as LSwitch} from "@/components";

export default {
  name: 'home-page-regular-mode-visual-editor',
  components: {
    LiveCoverageHeaderSection,
    LSwitch,
  },
  props: ['draft'],
  watch: {
    $route(to, from) {
      this.resetSectionsData();
      this.componentKey++;
      this.initializeComponent();
    }
  },
  data() {

    return {
      componentKey: 0,
      dir: null,
      formData: {
        start_date: null,
        end_date: null,
        lang: null,
        is_active:false,
      },
      LSwitch,
      liveCoverageHeaderData: {}
    }
  },
  mounted() {
    this.initializeComponent();
  },
  methods: {
    async initializeComponent() {
      const path = window.location.href;
      this.formData.lang = path.substring(path.lastIndexOf('/') + 1);
      this.loader = this.$loading.show({
        container: this.$refs.groupForm
      });
      const rtlLang = ['ar'];
      if (rtlLang.includes(this.formData.lang)) {
        this.dir = 'rtl';
      } else {
        this.dir = 'ltr';
      }
      await this.getVisualEditor();
      this.loader.hide();
    },
    saveSectionData(data) {
      data.key = "live_coverage_header";
      this.axios.post("live-coverage/save-content", data).then((response) => {
        this.getVisualEditor();
      }).catch((error) => {
        console.error(error);
      })
    },
    async saveSectionPublish() {
      this.loader = this.$loading.show({
        container: this.$refs.groupForm
      });
      this.formData.key = "live_coverage_header";
      this.axios.post("live-coverage/save-publish", this.formData).then(async(response) => {
        await this.getVisualEditor();
        this.loader.hide();
      }).catch((error) => {
        console.error(error);
        this.loader.hide();
      })
    },
    resetSectionsData() {
      this.liveCoverageHeaderData = {};
    },
    async getVisualEditor() {
      return new Promise(async (resolve, reject) => {
        this.axios.get("visual-editor/get-content?lang=" + this.formData.lang).then((response) => {
          this.liveCoverageHeaderData = response.data.liveCoverageHeaderSection;
          this.formData.start_date = this.vueDate( response.data.liveCoverageHeaderSection.start_date);
          this.formData.end_date = this.vueDate( response.data.liveCoverageHeaderSection.end_date);
          this.formData.is_active = !!response.data.liveCoverageHeaderSection.is_active;
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          return resolve(true);
        });
      });
    },
    vueDate(date) {
      return date ? date.replace(' ', 'T') : date;
    },
  }
}
</script>
<style>

.rtl-class {
  direction: rtl;
  text-align: right;
}

fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  margin: 5px 5px 1.5em 5px !important;
  padding: 0px !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
  font-size: 1.2em !important;
  color: #000;
  background-color: #0000;
}

.visual-editor-btn {
  cursor: pointer;
}

.news-card-title {
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  max-height: 40px;
}

.news-card-excerpt {
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  margin: 5px 0px;
  max-height: 120px;
}

.news-card-tag {
  font-size: 12px;
}

.news-card-source, .news-card-date {
  font-size: 10px;
}

.line {
  background-color: #000000;
}
</style>

