<template>
  <el-dialog
    center
    @close="closeModal()"
    :visible="visible">

    <div class="col-12">
      <fg-select
        name="column_type"
        size="large"
        filterable
        clearable
        placeholder="Type"
        :input-classes="'select-default'"
        :label="'Type'"
        :list="typeList"
        :listItemLabel="'name'"
        :listItemValue="'name'"
        v-model="selectedType"
        @change="selectionTypeChanged()"
      >
      </fg-select>
    </div>
    <div class="col-12" v-if="['category','classification'].includes(selectedType)">
      <fg-select
        name="column_type"
        size="large"
        filterable
        placeholder="Type"
        :input-classes="'select-default'"
        :label="'Retrieve news strategy'"
        :list="newsStrategiesList"
        :listItemLabel="'name'"
        :listItemValue="'name'"
        v-model="newsStrategy"
      >
      </fg-select>
    </div>
    <div v-if="selectedType === 'category'" class="col-md-12">
      <fg-select
        name="category_id"
        size="large"
        filterable
        clearable
        placeholder="Categories"
        :input-classes="'select-default'"
        :label="'Categories'"
        :list="categoriesData"
        :listItemLabel="'name'"
        :listItemValue="'id'"
        @change="selectionItemChanged()"
        v-model="selectedItemId">
      </fg-select>
      <paginated-select
        v-if="selectedItemId && newsStrategy !=='auto'"
        :labelTitle="'Choose News'"
        :isMultiple="true"
        @select-changed="setSelectedNews"
        :apiUrl="lang +'/news/paginatedNews'"
        :initData="draggableNews"
        :filterData="{key: 'category_id', value: selectedItemId}"
        :selectLimiter="selectCategoryNewsLimit"
        :key="paginatedCompKey"
      >
      </paginated-select>
    </div>
    <div v-if="selectedType === 'classification'" class="col-md-12">
      <fg-select
        name="classification_id"
        size="large"
        filterable
        clearable
        placeholder="Classifications"
        :input-classes="'select-default'"
        :label="'Classifications'"
        :list="classificationData"
        :listItemLabel="'name'"
        :listItemValue="'id'"
        @change="selectionItemChanged()"
        v-model="selectedItemId">
      </fg-select>
      <paginated-select
        v-if="selectedItemId && newsStrategy !=='auto'"
        :labelTitle="'Choose News'"
        :isMultiple="true"
        @select-changed="setSelectedNews"
        :apiUrl="lang +'/news/paginatedNews'"
        :initData="draggableNews"
        :filterData="{key: 'classification_id', value: selectedItemId}"
        :selectLimiter="selectCategoryNewsLimit"
        :key="paginatedCompKey"
      >
      </paginated-select>
    </div>
    <div v-if="selectedType === 'case'" class="col-md-12">

      <paginated-select
        :labelTitle="'Choose Case'"
        :isMultiple="false"
        @select-changed="setSelectedCase"
        :apiUrl="'cases/paginatedCases'"
        :initData="selectedCase"
        :lang="lang"
        :key="paginatedCompKey"
      >
      </paginated-select>
    </div>

    <draggable v-if="selectedItemId && newsStrategy !=='auto'" class="container" v-model="draggableNews" group="news" @start="drag=true" @end="moveEventFunction">
      <div v-for="(item, x) in draggableNews" :key="'Item_'+x">
        <card class="mt-2 mb-0">
          <span class="badge badge-success mx-2">{{ x + 1 }}</span>
          <span>{{ item.title }}</span>
        </card>
      </div>
    </draggable>
    <div slot="footer" class="dialog-footer">
      <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
      <button class="btn btn-info" @click="confirmModal()">Confirm</button>
    </div>
  </el-dialog>
</template>

<script>
import {Dialog, Option, Select} from 'element-ui'
import draggable from 'vuedraggable';
import PaginatedSelect from "@/components/paginatedSelect";
import FgSelect from "@/components/Inputs/formGroupSelect";

export default {
  name: 'select-row-modal',
  components: {
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    draggable,
    PaginatedSelect,
    FgSelect
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    },
    typeList:{
      type: Array,
    },
    selectClassificationNewsLimit: {
      type: Number
    },
    selectCategoryNewsLimit: {
      type: Number
    },
    classificationData: {
      type: Array,
    },
    categoriesData: {
      type: Array,
    },
    modalData: {
      type: Object,
    },
  },
  data() {

    return {
      selectedItemId:null,
      paginatedCompKey: 0,
      draggableNews: [],
      newsList: [],
      lang: null,
      selectedType:null,
      selectedCase:null,
      newsStrategy:'auto',
      newsStrategiesList:[{name :'selected'},{name:'auto'}],
    }
  },
  watch: {
    modalData: {
      handler: function (newValue) {
        this.setModalData();
      },
      deep: true
    }
  },
  mounted() {
    this.setModalData();
  },
  methods: {
    setModalData(){
      const path = window.location.href;
      this.lang = path.substring(path.lastIndexOf('/') + 1);
      if(this.modalData){
        this.newsStrategy = this.modalData.newsStrategy?this.modalData.newsStrategy:'auto';
        if(this.modalData.item){
          this.selectedItemId = this.modalData.item.id;
          if(this.modalData.type === 'case'){
            this.selectedCase = {
              id:this.modalData.item.id,
              title:this.modalData.item.title
            };
          }
        }

        this.selectedType = this.modalData.type;
        this.draggableNews= [];
        if(this.modalData.news){
          this.modalData.news.map((element, index) => {
            this.draggableNews.push({id: element.id, title: element.title});
          });
        }else{
          this.draggableNews= [];
        }

      }else{
        this.selectedItemId = null;
        this.newsStrategy = 'auto';
        this.selectedType =null;
        this.draggableNews = [];
      }
    },
    closeModal() {
      this.$emit('close');
    },
    confirmModal() {
      let modalData = {
        type:this.selectedType,
        itemId:this.selectedItemId,
        selectedNews:this.draggableNews,
        newsStrategy:this.newsStrategy,
      };
      modalData.selectedNews.map((element, index) => {
        element.sort_order = index + 1;
      });
      this.$emit('saveData', modalData);
      this.closeModal();
    },
    moveEventFunction() {
      // this.news.map((element, index) => { element.sort_order =  index})
    },
    selectionTypeChanged(){
      this.selectedItemId = null;
      this.draggableNews = [];
      this.selectedCase = null;
      this.paginatedCompKey++;
    },
    selectionItemChanged(){
      this.draggableNews = [];
      this.paginatedCompKey++;
    },
    setSelectedNews(selectedNews) {
      this.draggableNews = selectedNews;
    },
    setSelectedCase(selectedCase){
      this.selectedItemId = selectedCase.id;
    },
  }

}
</script>

