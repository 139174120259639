<template>

  <div class="row" ref="groupForm">

    <div class="col-12">
      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">
            {{ formTitle + " " + formData.name }}
          </h4>
          <a
            class="btn btn-info btn-wd export_excel" style="display:flex;"
            @click="openNewItemModal()"> Add Menu Item

          </a>
        </div>

        <div class="card-body">
          <menu-render :key="compKey" v-model="formData.items"
                       :categories="categories"
                       :pages="pages"
                       :classifications="classifications">

          </menu-render>
        </div>

        <div class="card-footer text-right">
          <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit()"
                    nativeType="submit"
                    type="info" wide>Submit
          </l-button>
          <l-button @click="$router.push('/visual-editors/menus/'+formData.lang)" type="danger" wide>Cancel
          </l-button>
        </div>
      </card>
    </div>
    <add-new-menu-item-modal
      :visible="newItemModalVisibility"
      :categories="categories"
      :pages="pages"
      :classifications="classifications"
      @close="closeNewItemModal"
      @addItem="saveNewItem"
    >
    </add-new-menu-item-modal>
  </div>
</template>
<script>

import MenuRender from "@/pages/visualEditors/menus/MenuRender";
import AddNewMenuItemModal from "@/pages/visualEditors/menus/AddNewMenuItemModal";

export default {
  components: {
    AddNewMenuItemModal,
    MenuRender,
  },
  data() {
    return {
      loader: {},
      id: undefined,
      newItemModalVisibility: false,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      categories: [],
      classifications: [],
      pages: [],
      compKey: 0,
      formData: {
        name: "",
        lang: null,
        items: [],
      },
    };
  },
  created() {
    const path = window.location.href;
    this.formData.lang = path.substring(path.lastIndexOf('/') + 1)
  },
  watch: {
    formData: {
      handler: function (newValue) {
        this.compKey++;
      },
      deep: true
    }
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.groupForm
    });
    let data = {'lang': this.formData.lang}
    this.axios.post("menus/builder", data).then((response) => {
      this.categories = response.data.categories;
      this.classifications = response.data.classifications;
      this.pages = response.data.pages;
      this.id = this.$route.params['id'];
      if (this.id !== undefined) {
        this.formTitle = "Edit";
        this.getMenu();
      }
    }).catch((error) => {
      console.error(error);
    })

  },


  methods: {
    openNewItemModal() {
      this.newItemModalVisibility = true;
    },
    closeNewItemModal() {
      this.newItemModalVisibility = false;
    },
    saveNewItem(item) {
      item.sort_order = this.formData.items.length + 1;
      this.formData.items.push(item);
    },
    getMenu() {
      let data = {
        'id': this.id,
        'lang': this.formData.lang
      };
      this.axios.post("menus/get", data).then((response) => {
        this.formData = response.data;
        this.formData.items = response.data.items_backend;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Menu Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {

        }
      })
    },
    handleSubmit() {
      let request;
      let successMessage;
      this.submitting = true;

      request = this.axios.put("menus/update/" + this.id, this.formData);
      successMessage = "Menu Updated Successfully";

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/visual-editors/menus/" + this.formData.lang);
      }).catch((error) => {
        if (error.response.status === 422) {

        } else {

        }
      }).finally(() => {
        this.submitting = false;
      })
    },

  }
}
</script>

<style>
</style>
