<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Menu List</h4>
        </div>
        <div>
          <general-data-table
            ref="table"
            :lang="lang"
            :row-key="'id'"
            :key="componentKey"
            :api-url="'menus/index'"
            @reorder="reorder($event)">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :prop="column.value"
                             :sortable="column.sortable"
                             :label="column.label">
              <template slot-scope="scope">
                <div v-if="column.value === 'color'">
                  <span :style="{padding: '35px', backgroundColor: scope.row[column.value] }"></span>
                </div>

                <span v-else>{{ scope.row[column.value] }}</span>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <template>
                  <router-link
                    v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/menus/update'])"
                    v-tooltip.top-center="'Edit'"
                    class="btn-warning btn-simple btn-link"
                    :to="'/visual-editors/menus/edit/'+scope.row.id+'/'+lang">
                    <i class="fa fa-edit"></i>
                  </router-link>
                </template>


              </template>
            </el-table-column>
          </general-data-table>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import {Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";

export default {
  components: {
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
    LSwitch
  },

  data() {

    return {
      tableColumns: [
        {label: 'Name', value: 'name', minWidth: '200', align: 'center'},
      ],
      fixedColumn: null,
      lang: '',
      componentKey: 0,
    }
  },
  created() {
    this.responsiveViewPort();
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1)
  },
  mounted() {
  },
  watch: {
    $route(to, from) {
      const path = window.location.href;
      this.lang = path.substring(path.lastIndexOf('/') + 1);
      this.componentKey++;
    }
  },
  methods: {
    responsiveViewPort() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.fixedColumn = null;
      } else {
        this.fixedColumn = 'right';
      }
    },


  },
}
</script>

