<template>
  <div class="container">
    <h3 class="section-title">
      <span style="font-size:20px; margin:10px">
        <router-link v-if="caseId && $store.getters['auth/haveOneOfPermissions']([lang+'/cases/update'])"
                     v-tooltip.top-center="'Edit Case'"
                     class="btn-warning btn-simple btn-link"
                     :to="'/cases/edit/'+caseId+'/'+lang">
        <i class="fa fa-edit"></i></router-link>
      </span>
      {{ sectionTitle }}
    </h3>
    <div class="row section-container">
      <div v-bind:class="{ 'col-4': campaignMode , 'col-6': !campaignMode, }">

      </div>
      <div v-bind:class="{ 'col-8': campaignMode , 'col-6': !campaignMode, }" class="text-news-container">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <template v-for="(textNews,textNewsIndex) in textNews1">
                <news-text-card class="text-news" :item="textNews"></news-text-card>
              </template>
            </div>
            <div class="col-6">
              <div class="follow-case-btn-container" v-if="textNews2 && textNews2.length">
                <button class="follow-case-btn">{{ followCaseText }}</button>
              </div>
              <template v-for="(textNews,textNewsIndex) in textNews2">
                <news-text-card class="text-news" :item="textNews"></news-text-card>
              </template>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>

import NewsTextCard from "@/pages/visualEditors/homePage/NewsTextCard";
import NewsHorizontalCard from "@/pages/visualEditors/homePage/NewsHorizontalCard";

export default {
  name: 'case-news-row-section',
  components: {NewsHorizontalCard, NewsTextCard},
  props: {
    sectionData: {
      type: Object,
    },
    campaignMode: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    sectionData: {
      handler: function (newValue) {
        this.setSectionData();
      },
      deep: true
    }
  },
  mounted() {
    this.setSectionData();
  },
  data() {
    return {
      lang: null,
      sectionTitle: '',
      caseId: null,
      textNews1: [],
      textNews2: [],
      followCaseText: '',
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    async setSectionData() {
      const path = window.location.href;
      this.lang = path.substring(path.lastIndexOf('/') + 1);
      switch (this.lang) {
        case 'ar':
          this.followCaseText = 'تابع الملف';
          break;
        case 'en':
          this.followCaseText = 'follow case';
          break;
        case 'fr':
          this.followCaseText = "suivre l'affaire";
          break;
        case 'es':
          this.followCaseText = 'seguir caso';
          break;
      }
      await this.resetSectionData();
      if (this.sectionData && this.sectionData.item) {
        this.sectionTitle = this.sectionData.item.title;
        this.caseId = this.sectionData.item.id;
        for (const news of this.sectionData.item.caseNews) {
          if (this.textNews1.length < 3) {
            this.textNews1.push(news);
          } else {
            if (this.textNews2.length < 3) {
              this.textNews2.push(news);
            }
          }

        }
      } else {
        await this.resetSectionData();
      }
    },
    async resetSectionData() {
      return new Promise(async (resolve, reject) => {
        this.sectionTitle = '';
        this.caseId = null;
        this.textNews1 = [];
        this.textNews2 = [];
        return resolve();
      });
    }

  }

}
</script>
<style>
.section-container {
  justify-content: space-evenly;
}

.section-title {
  margin: 10px 50px;
  font-weight: bold;
}

.main-news {
  height: 75%;
}

.main-news .images-section {
  height: 60%;
  display: flex;
  justify-content: space-between;
}

.main-news .images-section .main-news-image {
  flex-basis: 59%;
}

.main-news .images-section .main-news-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-news .images-section .other-news-image {
  flex-basis: 39%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.main-news .images-section .secondary-news-image {
  flex-basis: 49%;
}

.main-news .images-section .other-news-image .other-images {
  display: flex;
  justify-content: space-between;
  flex-basis: 49%;
}

.main-news .images-section .other-news-image div {
  flex-basis: 48%;
}

.main-news .images-section .other-news-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.content-section {
  display: flex;
  flex-flow: column;
}

.main-news-title-section {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  max-height: 40px;
}

.main-news-title {
  flex-grow: 2;
  max-width: 70%;
  overflow: hidden;
}

.main-news-date {
  font-size: 10px;
}

.main-news-excerpt {
  font-size: 12px;
  overflow: hidden;
  margin: 5px 0px;
  max-height: 50px;
}

.follow-case-btn-container{
  text-align: end;
}
.follow-case-btn {
  padding: 4px 40px;
  border-radius: 10px;
  background-color: inherit;
  margin-bottom: 10px;
}
</style>
