<template>
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <i @click="openSelectRowModal()" class="fas fa-pen visual-editor-btn"></i>
    </legend>
    <div class="container">
      <div class="row">
        <h3 class="section-title">
          {{ sectionTitle }}
        </h3>
      </div>
      <div class="row">
        <div class="col-12">
          <img class="news-image-section5-image-header card-img-top" v-if="mainNews.featured_images && mainNews.featured_images[0]"
               :src="getUrl()+mainNews.featured_images[0].image"
               alt="no image for this news">
          <p class="bold-news-font">{{ mainNews.title }}</p>
        </div>
        <template v-for="(newsItem,textNewsIndex) in listNews1">
          <div class="col-4">
            <div class="news-image-section5-under-header-horizontal">
              <img class="card-img-top" v-if="newsItem.featured_images && newsItem.featured_images[0]"
                   :src="getUrl()+newsItem.featured_images[0].image"
                   alt="no image for this news">
              <span v-if="newsItem.category" :style="'backgroundColor:' + newsItem.category.color">
                 {{ newsItem.category.name }}
              </span>
            </div>

            <p class="bold-news-font">{{ newsItem.title }}</p>
          </div>
        </template>

        <template v-for="(newsItem,textNewsIndex) in listNews2">
          <div class="col-12">
            <div class="news-image-section5-under-header-vertical">
              <div class="image-section">
                <img class="card-img-top"
                     v-if="newsItem.featured_images && newsItem.featured_images[0]"
                     :src="getUrl()+newsItem.featured_images[0].image"
                     alt="no image for this news">
              </div>
              <div class="text-section">
                <span v-if="newsItem.category" :style="'backgroundColor:' + newsItem.category.color">
                   {{ newsItem.category.name }}
                </span>
                <p class="bold-news-font">{{ newsItem.title }}</p>
              </div>
            </div>
          </div>
        </template>

      </div>
    </div>
    <select-row-modal
      :visible="selectRowModalVisibility"
      :selectedNews="modalSelectedList"
      :selectCategoryNewsLimit="6"
      :selectClassificationNewsLimit="6"
      :selectLimit="6"
      :modalData="sectionData"
      :typeList="typeList"
      :categories-data="categoriesData"
      :classification-data="classificationData"
      @close="closeSelectRowModal"
      @saveData="saveData"
    >

    </select-row-modal>
  </fieldset>
</template>

<script>

import SelectRowModal from "@/pages/visualEditors/homePage/SelectRowModal";

export default {
  name: 'category-classification-column-with-header',
  components: {
    SelectRowModal
  },
  props: {
    typeList: {
      type: Array,
    },
    sectionData: {
      type: Object,
    },
    sectionKey: {
      type: String,
    },
    classificationData: {
      type: Array
    },
    categoriesData: {
      type: Array
    },
  },
  mounted() {
    this.setSectionData();
  },
  watch: {
    sectionData: {
      handler: function (newValue) {
        this.setSectionData();
      },
      deep: true
    }
  },
  data() {

    return {
      lang: null,
      sectionTitle: '',
      mainNews: {},
      listNews1: [],
      listNews2: [],
      modalSelectedList: [],
      selectRowModalVisibility: false,
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    openSelectRowModal() {
      this.selectRowModalVisibility = true;
    },
    closeSelectRowModal() {
      this.selectRowModalVisibility = false;
    },
    async setSectionData() {
      const path = window.location.href;
      this.lang = path.substring(path.lastIndexOf('/') + 1);
      await this.resetSectionData();
      if (this.sectionData && this.sectionData.item) {
        this.sectionTitle = this.sectionData.item.name;
        let counter = 1;
        for (const news of this.sectionData.news) {
          if (counter !== 1) {
            if (this.listNews1.length < 3) {
              this.listNews1.push(news);
            } else {
              if (this.listNews2.length < 3) {
                this.listNews2.push(news);
              }
            }
          } else {
            this.mainNews = news;
          }
          counter++;
        }
      } else {
        await this.resetSectionData();
      }
    },
    async resetSectionData() {
      return new Promise(async (resolve, reject) => {
        this.sectionTitle = '';
        this.mainNews = {};
        this.listNews1 = [];
        this.listNews2 = [];
        return resolve();
      });
    },
    saveData(value) {
      let data = {
        'key': this.sectionKey,
        'value': value,
        'lang': this.lang,
      }
      this.$emit('saveData', data);
    }
  }

}
</script>
<style>
.bold-news-font {
  font-weight: bold;
  margin-top: 10px;
  font-size: 14px;
  overflow: hidden;
  max-height: 40px;
}
.news-image-section5-image-header{
  height: 300px;
}
.news-image-section5-under-header-horizontal {
  position: relative;
}

.news-image-section5-under-header-horizontal img {
  height: 100px;
}

.news-image-section5-under-header-horizontal span {
  font-size: 9px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 2px 8px;
  color: #ffffff;
}
.news-image-section5-under-header-vertical{
  display:flex;
  margin-top: 10px;
}
.news-image-section5-under-header-vertical .image-section{
  flex-basis: 45%;
  height: 150px;
}
.news-image-section5-under-header-vertical .image-section img{
  height: 100%;
}
.news-image-section5-under-header-vertical .text-section{
  margin: 0 10px;
  flex-basis: 55%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.news-image-section5-under-header-vertical .text-section span{
  width:fit-content;
  color:#ffffff;
  padding: 2px 8px;
  font-size: 9px;
}
.news-image-section5-under-header-vertical{
  margin-bottom: 10px;
}
</style>
