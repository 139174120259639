<template>
  <div class="container">
    <h3 class="section-title">
      <span style="font-size:20px; margin:10px">
        <router-link v-if="caseId && $store.getters['auth/haveOneOfPermissions']([lang+'/cases/update'])"
                     v-tooltip.top-center="'Edit Case'"
                     class="btn-warning btn-simple btn-link"
                     :to="'/cases/edit/'+caseId+'/'+lang">
        <i class="fa fa-edit"></i></router-link>
      </span>
      {{ sectionTitle }}
    </h3>
    <div class="row section-container">
      <div v-bind:class="{ 'col-5': !campaignMode , 'col-6': campaignMode, }" class="text-news-container">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <template v-for="(textNews,textNewsIndex) in textNews1">
                <news-text-card class="text-news" :item="textNews"></news-text-card>
              </template>
            </div>
            <div class="col-6">
              <template v-for="(textNews,textNewsIndex) in textNews2">
                <news-text-card class="text-news" :item="textNews"></news-text-card>
              </template>
            </div>
          </div>

        </div>

      </div>
      <div v-if="campaignMode" class="col-6">
        <div class="main-news-case-campaign">
          <div class="images-section-case-campaign">
            <div class="main-news-image-case-campaign">
              <img v-if="mainNews.featured_images && mainNews.featured_images[0]"
                   :src="getUrl()+mainNews.featured_images[0].image"
                   alt="no image for this news">
              <img v-else alt="this news must contain featured images ">
            </div>
            <div class="main-news-other-images-case-campaign">
              <div>
                <img class="main-news-image" v-if="mainNews.featured_images && mainNews.featured_images[1]"
                     :src="getUrl()+mainNews.featured_images[1].image"
                     alt="no image for this news">
                <img v-else alt="this news must contain  more featured images">
              </div>
              <div>
                <img class="main-news-image" v-if="mainNews.featured_images && mainNews.featured_images[2]"
                     :src="getUrl()+mainNews.featured_images[2].image"
                     alt="no image for this news">
                <img v-else alt="this news must contain  more featured images ">

              </div>
              <div>
                <img class="main-news-image" v-if="mainNews.featured_images && mainNews.featured_images[3]"
                     :src="getUrl()+mainNews.featured_images[3].image"
                     alt="no image for this news">
                <img v-else alt="this news must contain  more featured images ">
              </div>

            </div>
          </div>
          <div class="content-section">
            <div class="main-news-title-section">
              <div class="main-news-title">{{ mainNews.title }}</div>
              <div class="main-news-date">{{ mainNews.date }}</div>
            </div>
            <div class="main-news-excerpt">
              {{ mainNews.excerpt }}
            </div>
            <div v-if="mainNews.category" :style="'color:' + mainNews.category.color" class="news-card-tag">
              <template v-for="(ele, index) in mainNews.tags">
                <span>{{ ele.name }}</span>
                <span v-if="mainNews.tags && index+1 !== mainNews.tags.length" class="vertical-line mx-1"></span>
              </template>
            </div>
            <div class=" mt-2 d-flex justify-content-start">
              <div v-if="mainNews.category" :style="'backgroundColor:' + mainNews.category.color"
                   class="badge badge-success p-2">
                {{ mainNews.category.name }}
              </div>
              <div class="mx-2">
                <p v-if="mainNews.source" class="news-card-source text-muted py-0 my-0">{{ mainNews.source.name }}</p>
                <p class="news-card-date text-muted py-0 my-0">{{ mainNews.date }}</p>
              </div>
            </div>

          </div>

        </div>
      </div>
      <div v-else class="col-5">
        <div class="main-news">
          <div class="images-section">
            <div class="main-news-image">
              <img v-if="mainNews.featured_images && mainNews.featured_images[0]"
                   :src="getUrl()+mainNews.featured_images[0].image"
                   alt="no image for this news">
              <img v-else alt="this news must contain featured images ">
            </div>
            <div class="other-news-image">
              <div class="secondary-news-image">
                <img class="main-news-image" v-if="mainNews.featured_images && mainNews.featured_images[1]"
                     :src="getUrl()+mainNews.featured_images[1].image"
                     alt="no image for this news">
                <img v-else alt="this news must contain  more featured images">
              </div>
              <div class="other-images">
                <div>
                  <img class="main-news-image" v-if="mainNews.featured_images && mainNews.featured_images[2]"
                       :src="getUrl()+mainNews.featured_images[2].image"
                       alt="no image for this news">
                  <img v-else alt="this news must contain  more featured images ">
                </div>
                <div>
                  <img class="main-news-image" v-if="mainNews.featured_images && mainNews.featured_images[3]"
                       :src="getUrl()+mainNews.featured_images[3].image"
                       alt="no image for this news">
                  <img v-else alt="this news must contain  more featured images ">
                </div>
              </div>
            </div>
          </div>
          <div class="content-section">
            <div class="main-news-title-section">
              <div class="main-news-title">{{ mainNews.title }}</div>
              <div class="main-news-date">{{ mainNews.date }}</div>
            </div>
            <div class="main-news-excerpt">
              {{ mainNews.excerpt }}
            </div>
            <div v-if="mainNews.category" :style="'color:' + mainNews.category.color" class="news-card-tag">
              <template v-for="(ele, index) in mainNews.tags">
                <span>{{ ele.name }}</span>
                <span v-if="mainNews.tags && index+1 !== mainNews.tags.length" class="vertical-line mx-1"></span>
              </template>
            </div>
            <div class=" mt-2 d-flex justify-content-start">
              <div v-if="mainNews.category" :style="'backgroundColor:' + mainNews.category.color"
                   class="badge badge-success p-2">
                {{ mainNews.category.name }}
              </div>
              <div class="mx-2">
                <p v-if="mainNews.source" class="news-card-source text-muted py-0 my-0">{{ mainNews.source.name }}</p>
                <p class="news-card-date text-muted py-0 my-0">{{ mainNews.date }}</p>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import NewsTextCard from "@/pages/visualEditors/homePage/NewsTextCard";
import NewsHorizontalCard from "@/pages/visualEditors/homePage/NewsHorizontalCard";

export default {
  name: 'case-with-main-news-row-section',
  components: {NewsHorizontalCard, NewsTextCard},
  props: {
    sectionData: {
      type: Object,
    },
    campaignMode: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    sectionData: {
      handler: function (newValue) {
        this.setSectionData();
      },
      deep: true
    }
  },
  mounted() {
    this.setSectionData();
  },
  data() {
    return {
      lang: null,
      sectionTitle: '',
      caseId: null,
      mainNews: {},
      textNews1: [],
      textNews2: [],
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    async setSectionData() {
      const path = window.location.href;
      this.lang = path.substring(path.lastIndexOf('/') + 1);
      await this.resetSectionData();
      if (this.sectionData && this.sectionData.item) {
        this.sectionTitle = this.sectionData.item.title;
        this.caseId = this.sectionData.item.id;
        let counter = 1;
        for (const news of this.sectionData.item.caseNews) {
          if (counter !== 1) {
            if (this.textNews1.length < 3) {
              this.textNews1.push(news);
            } else {
              if (this.textNews2.length < 3) {
                this.textNews2.push(news);
              }
            }
          } else {
            this.mainNews = news;
          }
          counter++;
        }
      } else {
        await this.resetSectionData();
      }
    },
    async resetSectionData() {
      return new Promise(async (resolve, reject) => {
        this.sectionTitle = '';
        this.caseId = null;
        this.mainNews = {};
        this.textNews1 = [];
        this.textNews2 = [];
        return resolve();
      });
    }

  }

}
</script>
<style>
.section-container {
  justify-content: space-evenly;
}

.section-title {
  margin: 10px 50px;
  font-weight: bold;
}

.main-news {
  height: 75%;
}

.main-news .images-section {
  height: 60%;
  display: flex;
  justify-content: space-between;
}

.main-news .images-section .main-news-image {
  flex-basis: 59%;
}

.main-news .images-section .main-news-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-news .images-section .other-news-image {
  flex-basis: 39%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.main-news .images-section .secondary-news-image {
  flex-basis: 49%;
}

.main-news .images-section .other-news-image .other-images {
  display: flex;
  justify-content: space-between;
  flex-basis: 49%;
}

.main-news .images-section .other-news-image div {
  flex-basis: 48%;
}

.main-news .images-section .other-news-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.content-section {
  display: flex;
  flex-flow: column;
}

.main-news-title-section {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  max-height: 40px;
}

.main-news-title {
  flex-grow: 2;
  max-width: 70%;
  overflow: hidden;
}

.main-news-date {
  font-size: 10px;
}

.main-news-excerpt {
  font-size: 12px;
  overflow: hidden;
  margin: 5px 0px;
  max-height: 50px;
}

.main-news-case-campaign {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.main-news-case-campaign img {
  width: 100%;
}

.images-section-case-campaign {
  display: flex;
  flex-flow: column;
}

.main-news-image-case-campaign {
  display: flex;
}

.main-news-other-images-case-campaign {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}
.main-news-other-images-case-campaign div{
  flex-basis: 33%;
}
.main-news-other-images-case-campaign img{
  height: 100%;
}

</style>
