<template>
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <i @click="openSelectNewsModal()" class="fas fa-pen visual-editor-btn"></i>
    </legend>
    <div class="container my-5 mx-2">
      <div class="row ">
        <div v-bind:class="{ 'col-3': !campaignMode,'col-4': campaignMode, }" v-for="(item, index) in selectedNewsSection">
          <news-card :item="item"></news-card>
        </div>
      </div>
    </div>
    <select-news-modal :visible="selectNewsModalVisibility"
                       :selectedNews="modalSelectedList"
                       :selectLimit="selectedNewsLimit"
                       @close="closeSelectNewsModal"
                       @selectedNews="saveData"
    >
    </select-news-modal>
  </fieldset>
</template>

<script>
import SelectNewsModal from '@/pages/visualEditors/homePage/SelectNewsModal.vue';
import NewsCard from '@/pages/visualEditors/homePage/NewsCard.vue';

export default {
  name: 'second-section-row-2',
  components: {
    SelectNewsModal,
    NewsCard,
  },
  watch: {
    selectedNews: {
      handler: function (newValue) {
        this.setModalData();
      },
      deep: true
    }
  },
  props: {
    selectedNews: {
      type: Array,
    },
    campaignMode: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1);
    if(this.campaignMode){
      this.selectedNewsLimit = 3;
    }
  },
  data() {

    return {
      lang: null,
      modalSelectedList: [],
      selectedNewsSection:[],
      selectNewsModalVisibility: false,
      selectedNewsLimit:4,
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    openSelectNewsModal() {
      this.selectNewsModalVisibility = true;
    },
    closeSelectNewsModal() {
      this.selectNewsModalVisibility = false;
    },
    setModalData() {
      for(const news of this.selectedNews){
        if(this.selectedNewsSection.length < 4){
          if(this.campaignMode){
            if(this.selectedNewsSection.length < 3){
              this.selectedNewsSection.push(news);
            }
          }else{
            this.selectedNewsSection.push(news);
          }
        }
        this.modalSelectedList.push({id: news.id, title: news.title});
      }
      if(this.campaignMode){
        this.selectedNewsLimit = 3;
      }
    },
    saveData(value) {
      value.map((element, index) => {
        element.sort_order = index + 1;
      });
      let data = {
        'key': 'home_page_section_2_row_2',
        'value': value,
        'lang': this.lang,
      }
      this.$emit('saveData', data);
    }
  }

}
</script>
<style>
.section-2-title-text {
  overflow: hidden;
  margin: 5px 0px;
  max-height: 40px;
  font-size: 14px;
  font-weight: bold;
}

.section-2-excerpt-text {
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  margin: 5px 0px;
  max-height: 120px;
}
</style>
