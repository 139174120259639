<template>
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <i @click="openSelectRowModal()" class="fas fa-pen visual-editor-btn"></i>
    </legend>
    <div class="container">
      <div class="row">
        <h3 class="section-title">
          {{ sectionTitle }}
        </h3>
      </div>
      <div class="row">
        <template v-for="(newsItem,textNewsIndex) in listNews">
          <div class="col-12">
            <div class="news-image-section5-news-vertical">
              <div class="image-section">
                <img class="card-img-top" v-if="newsItem.featured_images && newsItem.featured_images[0]"
                     :src="getUrl()+newsItem.featured_images[0].image"
                     alt="no image for this news">
              </div>
              <div class="text-section">
                <span v-if="newsItem.category && newsItem.author" :style="'color:' + newsItem.category.color">
                   {{ newsItem.author.name }}
                </span>
                <p>{{ newsItem.title }}</p>
                <div class="bottom-line"></div>
              </div>
            </div>
          </div>
        </template>

      </div>
    </div>
    <select-row-modal
      :visible="selectRowModalVisibility"
      :selectedNews="modalSelectedList"
      :selectCategoryNewsLimit="5"
      :selectClassificationNewsLimit="5"
      :selectLimit="5"
      :modalData="sectionData"
      :typeList="typeList"
      :categories-data="categoriesData"
      :classification-data="classificationData"
      @close="closeSelectRowModal"
      @saveData="saveData"
    >

    </select-row-modal>
  </fieldset>
</template>

<script>

import SelectRowModal from "@/pages/visualEditors/homePage/SelectRowModal";

export default {
  name: 'category-classification-column-list',
  components: {
    SelectRowModal
  },
  props: {
    typeList: {
      type: Array,
    },
    sectionData: {
      type: Object,
    },
    sectionKey: {
      type: String,
    },
    classificationData: {
      type: Array
    },
    categoriesData: {
      type: Array
    },
  },
  mounted() {
    this.setSectionData();
  },
  watch: {
    sectionData: {
      handler: function (newValue) {
        this.setSectionData();
      },
      deep: true
    }
  },
  data() {

    return {
      lang: null,
      sectionTitle: '',
      listNews: [],
      modalSelectedList: [],
      selectRowModalVisibility: false,
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    openSelectRowModal() {
      this.selectRowModalVisibility = true;
    },
    closeSelectRowModal() {
      this.selectRowModalVisibility = false;
    },
    async setSectionData() {
      const path = window.location.href;
      this.lang = path.substring(path.lastIndexOf('/') + 1);
      await this.resetSectionData();
      if (this.sectionData && this.sectionData.item) {
        this.sectionTitle = this.sectionData.item.name;
        this.listNews = this.sectionData.news;
      } else {
        await this.resetSectionData();
      }
    },
    async resetSectionData() {
      return new Promise(async (resolve, reject) => {
        this.sectionTitle = '';
        this.mainNews = {};
        this.listNews = [];
        return resolve();
      });
    },
    saveData(value) {
      let data = {
        'key': this.sectionKey,
        'value': value,
        'lang': this.lang,
      }
      this.$emit('saveData', data);
    }
  }

}
</script>
<style>
.news-image-section5-news-vertical{
  display:flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.news-image-section5-news-vertical .image-section{
  flex-basis: 45%;
  height: 150px;
}
.news-image-section5-news-vertical .image-section img{
  height: 100%;
}
.news-image-section5-news-vertical .text-section{
  margin: 0 10px;
  flex-basis: 55%;
  display: flex;
  flex-flow: column;
}
.news-image-section5-news-vertical .text-section span{
  width:fit-content;
}
.news-image-section5-news-vertical .text-section p{
  margin-top: 10px;
  font-size: 14px;
  overflow: hidden;
  flex-grow: 2;
  display: flex;
  align-items: center;
}
.bottom-line{
  width:20%;
  border-bottom: 1px solid #000000;
}
</style>
category-classification-column-list
