<template>
  <el-dialog
    center
    @close="closeModal()"
    :visible="visible">

    <div class="col-12">
      <fg-select
        name="column_type"
        size="large"
        filterable
        clearable
        placeholder="Type"
        :input-classes="'select-default'"
        :label="'Type'"
        :list="typeList"
        :listItemLabel="'name'"
        :listItemValue="'value'"
        v-model="selectedType"
        @change="selectionTypeChanged()"
      >
      </fg-select>
    </div>
    <div v-if="selectedType === 'category'" class="col-12">
      <fg-select
        name="category_id"
        size="large"
        filterable
        clearable
        placeholder="select category"
        :input-classes="'select-default'"
        :label="'select category'"
        :list="categories"
        :listItemLabel="'name'"
        :listItemValue="'id'"
        v-model="selectedItemId">
      </fg-select>
    </div>
    <div v-if="selectedType === 'classification'" class="col-12">
      <fg-select
        name="classification_id"
        size="large"
        filterable
        clearable
        placeholder="select classification"
        :input-classes="'select-default'"
        :label="'select classification'"
        :list="classifications"
        :listItemLabel="'name'"
        :listItemValue="'id'"
        v-model="selectedItemId">
      </fg-select>
    </div>
    <div v-if="selectedType === 'page'" class="col-12">
      <fg-select
        name="page_id"
        size="large"
        filterable
        clearable
        placeholder="select page"
        :input-classes="'select-default'"
        :label="'select page'"
        :list="pages"
        :listItemLabel="'page_name'"
        :listItemValue="'id'"
        v-model="selectedItemId">
      </fg-select>
    </div>

    <div v-if="selectedType" class="col-12">
      <fg-input v-if="['external_link','inner_link','sub_header'].includes(selectedType)" type="text"
                label="Label"
                name="Label"
                fou
                v-model="selectedLabel">
      </fg-input>
      <fg-input v-if="['external_link','inner_link'].includes(selectedType)" type="text"
                label="Link"
                name="link"
                fou
                v-model="selectedLink">
      </fg-input>
      <div  class="form-group pt-4">
        <label>Have Sub Menu <span v-if="selectedType==='sub_header'"> : Yes</span></label>&nbsp;
        <l-switch v-if="selectedType!=='sub_header'" v-model="selectedHaveSubMenu">
          <i class="fa fa-check" slot="on"></i>
          <i class="fa fa-times" slot="off"></i>
        </l-switch>
      </div>
    </div>
    <div v-if="selectedHaveSubMenu && ['classification','category'].includes(selectedType)" class="col-12">
      <fg-select
        name="column_type"
        size="large"
        filterable
        clearable
        placeholder="Sub Menu Type"
        :input-classes="'select-default'"
        :label="'Sub Menu Type'"
        :list="subMenuStrategyOptions"
        :listItemLabel="'name'"
        :listItemValue="'value'"
        v-model="selectedSubMenuStrategy"
      >
      </fg-select>
    </div>


    <div slot="footer" class="dialog-footer">
      <div style="display: flex" role="alert" class="alert alert-danger" v-if="validationMsg">
        {{validationMsg}}
      </div>
      <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
      <button class="btn btn-info" @click="confirmModal()">Confirm</button>
    </div>
  </el-dialog>
</template>

<script>
import {Dialog, Option, Select} from 'element-ui'
import draggable from 'vuedraggable';
import PaginatedSelect from "@/components/paginatedSelect";
import FgSelect from "@/components/Inputs/formGroupSelect";
import {Switch as LSwitch} from "@/components";
export default {
  name: 'add-new-menu-item-modal',
  components: {
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    draggable,
    PaginatedSelect,
    FgSelect,
    LSwitch
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    },
    pages: {
      type: Array,
    },
    categories: {
      type: Array,
    },
    classifications: {
      type: Array,
    },
  },
  data() {

    return {
      typeList: [
        {name: 'inner link', value: 'inner_link'},
        {name: 'external link', value: 'external_link'},
        {name: 'sub header', value: 'sub_header'},
        {name: 'category', value: 'category'},
        {name: 'classification', value: 'classification'},
        {name: 'page', value: 'page'},
      ],
      subMenuStrategyOptions: [
        {name: 'last news', value: 'last_news'},
        {name: 'selected items', value: 'selected_items'},
      ],
      validationMsg:'',
      selectedItemId: null,
      selectedType: null,
      selectedLabel: null,
      selectedLink: null,
      selectedHaveSubMenu: false,
      selectedSubMenuStrategy: null,
    }
  },
  mounted() {

  },
  methods: {
    closeModal() {
      this.resetModal();
      this.$emit('close');
    },
    validItem(){
        if(!this.selectedType){
          this.validationMsg = "You must select type";
          return false;
        }
        if(['classification','category','page'].includes(this.selectedType)){
            if(!this.selectedItemId){
              this.validationMsg = "You must select item";
              return false;
            }
            if(this.selectedHaveSubMenu){
              if(!this.selectedSubMenuStrategy && this.selectedType !=='page'){
                this.validationMsg = "You must select sub menu type";
                return false;
              }
            }
        }
      if(['sub_header','external_link','inner_link'].includes(this.selectedType)){
        if(!this.selectedLabel){
          this.validationMsg = "You must enter label";
          return false;
        }
        if(!this.selectedLink && this.selectedType !=='sub_header'){
          this.validationMsg = "You must enter link";
          return false;
        }
      }
        return true;
    },
    confirmModal() {
      if(this.validItem()){
        let item = {
          'itemId':this.selectedItemId ,
          'label': this.selectedLabel ,
          'link':this.selectedLink ,
          'haveSubMenu':this.selectedHaveSubMenu ,
          'subMenuStrategy':this.selectedSubMenuStrategy ,
          'type':this.selectedType,
          'items':[],
        };
        if(this.selectedItemId){
          if(this.selectedType === 'category'){
            item.item = this.categories.find(cat => cat.id===this.selectedItemId);
          }
          if(this.selectedType === 'page'){
            item.item = this.pages.find(cat => cat.id===this.selectedItemId);
          }
          if(this.selectedType === 'classification'){
            item.item = this.classifications.find(cat => cat.id===this.selectedItemId);
          }
        }
        this.$emit('addItem', item);
        this.resetModal();
        this.closeModal();
      }
    },
    selectionTypeChanged() {
      this.selectedItemId = null;
      this.selectedLabel = null;
      this.selectedLink = null;
      this.selectedHaveSubMenu = false;
      this.selectedSubMenuStrategy = null;
      if(this.selectedType === 'sub_header'){
        this.selectedHaveSubMenu = true;
      }
    },
    resetModal(){
      this.selectedType = null;
      this.selectedItemId = null;
      this.selectedLabel = null;
      this.selectedLink = null;
      this.selectedHaveSubMenu = false;
      this.selectedSubMenuStrategy = null;
      this.validationMsg = '';
    }
  }

}
</script>

