<template>
  <el-dialog
    center
    @close="closeModal()"
    :visible="visible"
    dir="ltr">
    <div class="col-12">
      <h6>New Skin Item : </h6>
      <fg-select
        name="column_type"
        size="large"
        filterable
        clearable
        placeholder="Type"
        :input-classes="'select-default'"
        :list="typeList"
        :listItemLabel="'label'"
        :listItemValue="'value'"
        v-model="selectedCornerItemType"
      >
      </fg-select>
    </div>
    <div class="col-12" v-if="selectedCornerItemType === 'PANEL'">

      <div class="form-group">
        <fg-select
          size="large"
          filterable
          clearable
          placeholder="Select Group"
          :input-classes="'select-default'"
          :list="panelsGroups"
          :listItemLabel="'name'"
          :listItemValue="'id'"
          v-model="selectedPanelGroupId">
        </fg-select>

        <fg-select
          v-if="selectedPanelGroupId"
          size="large"
          filterable
          clearable
          placeholder="Select Group"
          :input-classes="'select-default'"
          :list="panels"
          :listItemLabel="'name'"
          :listItemValue="'id'"
          v-model="selectedPanelId">
        </fg-select>

      </div>
    </div>
    <div class="col-12" v-if="selectedCornerItemType === 'FORM'">
      <h6>build Form : </h6>
      <div class="form-group">
        <fg-select
          name="column_type"
          size="large"
          filterable
          clearable
          placeholder="Type"
          :input-classes="'select-default'"
          :list="typeFormList"
          :listItemLabel="'label'"
          :listItemValue="'value'"
          v-model="selectedCornerItemFormType"
        >
        </fg-select>
      </div>
    </div>
    <div class="col-12" v-if="selectedCornerItemType === 'POLL'">
      <div class="form-group">
        <fg-select
          size="large"
          filterable
          clearable
          placeholder="Select Poll"
          :input-classes="'select-default'"
          :list="polls"
          :listItemLabel="'title'"
          :listItemValue="'id'"
          v-model="selectedPollId">
        </fg-select>

      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
      <button class="btn btn-info" @click="confirmModal()">Confirm</button>
    </div>
  </el-dialog>
</template>

<script>

import {Dialog, Option, Select, Tooltip} from "element-ui";
import SingleFileUploaderFileManager from "@/components/FileManager/SingleFileUploaderFileManager";
import FgSelect from "@/components/Inputs/formGroupSelect";

export default {
  name: 'select-corner-item-modal',
  components: {
    FgSelect,
    SingleFileUploaderFileManager,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
  },
  props: {
    visible: {
      type: Boolean,
      default: 0
    },
  },
  watch: {
    selectedPanelGroupId: {
      handler: function (newValue) {
        this.panels = [];
        this.selectedPanelId = null;
        if (newValue) {
          this.getPanels();
        }
      },
      deep: true
    }
  },
  data() {
    return {
      lang: null,
      typeList: [{label: 'Panel', value: 'PANEL'}, {label: 'Form', value: 'FORM'}, {label: 'Poll', value: 'POLL'}],
      typeFormList: [{label: 'Newsletters', value: 'NEWSLETTERS'},{label: 'Jobs', value: 'JOBS'}],
      panelsGroups: [],
      panels: [],
      polls:[],
      selectedPanelGroupId: null,
      selectedPanelId: null,
      selectedPollId: null,
      selectedCornerItemType: null,
      selectedCornerItemFormType: null,

    }
  },
  async mounted() {
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1);
    this.getPanelGroups();
    this.getPolls();
  },
  methods: {
    resetModalData() {
      this.selectedCornerItemType = null;
      this.selectedCornerItemFormType = null;
      this.selectedPanelGroupId = null;
      this.panels = [];
      this.selectedPanelId = null;
      this.selectedPollId = null;
    },
    closeModal() {
      this.resetModalData();
      this.$emit('close');
    },
    confirmModal() {
      let modalData = {
        panelId: this.selectedPanelId,
        pollId: this.selectedPollId,
        type: this.selectedCornerItemType,
        form: this.selectedCornerItemFormType
      };
      this.$emit('saveData', modalData);
      this.resetModalData();
      this.closeModal();
    },
    getPanelGroups() {
      let data = {}
      this.axios.post("panels/builder", data).then((response) => {
        this.panelsGroups = response.data.groups;
      }).catch(() => {
      })
    },
    getPanels() {
      this.axios.get('panels/getActiveByGroup?groupId=' + this.selectedPanelGroupId)
        .then((response) => {
          this.panels = response.data;
        }).catch(() => {
      }).finally(() => {
      });
    },
    getPolls() {
      this.axios.get('polls/getAllByLang?lang=' + this.lang)
        .then((response) => {
          this.polls = response.data;
        }).catch(() => {
      }).finally(() => {
      });
    }
  }

}
</script>

