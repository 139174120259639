<template>
  <div class="d-flex  align-items-start flex-column">

    <p class="news-card-title mb-1">{{ item.title }}</p>
    <p class="news-text-card-excerpt text-muted">{{ item.excerpt }}</p>
    <div class="d-flex justify-content-start">
      <div class="mx-2">
        <p class="news-card-source text-muted py-0 my-0">{{ item.source.name }}</p>
        <p class="news-card-date text-muted py-0 my-0">{{ item.date }}</p>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'news-text-card',
  components: {},
  props: ['item'],
  data() {
    return {}
  },

  computed: {},

  mounted() {
  },
  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
  }

}
</script>
<style>

.news-card-title {
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  max-height: 40px;
}

.news-text-card-excerpt {
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  margin: 5px 0px;
  max-height: 120px;
}

.vertical-line {
  border-left: 2px solid black;
  height: 25px;
  left: 50%;
}

.news-card-source, .news-card-date {
  font-size: 10px;
}
</style>
