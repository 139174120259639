<template>
  <div>
    <label class="card-label">{{ labelTitle }}</label>
    <v-select
      :multiple="isMultiple"
      :label="labelAttribute"
      class="style-chooser"
      @option:deselected="selectChanged"
      @option:selected="selectChanged"
      :key="selectKey"
      v-model="draggableSelected"
      :options="paginated"
      :filterable="false"
      v-on:input="limiter"
      @search="onSearch">
      <li slot="list-footer" class="pagination prev-next">
        <button class="btn btn-info" :disabled="!hasPrevPage" @click="prevPage">Prev</button>
        <button class="btn btn-info" :disabled="!hasNextPage" @click="nextPage">Next</button>
      </li>
    </v-select>
  </div>
</template>

<script>
import {Dialog} from 'element-ui'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: 'paginated-select',

  components: {
    [Dialog.name]: Dialog,
    vSelect,
  },

  props: {
    labelAttribute:{
      type: String,
      default: 'title'
    },
    labelTitle: {
      type: String,
      default: 'Choose from select'
    },
    isMultiple: {
      type: Boolean,
      default: true
    },
    filterData: {
      type: Object,
      default() {
        return null
      }
    },
    apiUrl: {
      type: String,
      default: 'ar/news/paginatedNews'
    },
    lang: {
      type: String,
      default: null
    },
    limit: {
      type: Number,
      default: 5
    },
    initData: {

    },
    selectLimiter: {
      type: Number,
      default: 10000,
    },
    columnsName: {
      type: Array,
      default() {
        return ['id', 'title']
      }
    },
  },
  data() {
    return {
      draggableSelected: [],
      selectList: [],
      selectKey: 0,
      search: '',
      page: 0,
    }
  },
  mounted() {
    this.resetPaginator();
    this.draggableSelected = this.initData;
    this.dataPaginated();
  },
  watch: {
    initData: {
      handler: function (newValue) {
        this.draggableSelected = this.initData;
      },
      deep: true
    },
  },
  computed: {
    paginated() {
      return this.selectList;
    },
    hasNextPage() {
      return this.selectList.length > this.limit - 1;
    },
    hasPrevPage() {
      return this.page !== 1;
    },
  },
  methods: {
    selectChanged() {
      this.selectKey++;
      this.$emit('select-changed', this.draggableSelected);
    },
    confirmModal() {
      this.$emit('confirm');
    },
    dataPaginated() {
      let paginatorData = {
        page: this.page,
        per_page: this.limit,
        search: this.search,
        columns: this.columnsName,
        lang:this.lang,
        filters: this.filterData ? [this.filterData] : []
      };
      this.axios.post(this.apiUrl, paginatorData).then((response) => {
        this.selectList = response.data.dataList;
        this.dataCount = response.data.dataCount;
      }).catch((error) => {
      })
    },
    resetPaginator() {
      this.page = 1;
    },
    nextPage() {
      this.page++;
      this.dataPaginated();
    },
    prevPage() {
      this.page--;
      this.dataPaginated();
    },
    onSearch(query) {
      this.search = query
      this.page = 1
      this.dataPaginated();
    },
    limiter(e) {
      if (this.selectLimiter) {
        if (e.length > this.selectLimiter) {
          e.pop()
        }
      }
    },
  }
}
</script>

<style>
.line {
  position: absolute;
  left: 49%;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #ccc;
  z-index: 1;
}

.wordwrapper {
  text-align: center;
  height: 12px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -12px;
  z-index: 2;
}

.word {
  color: #ccc;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 3px;
  font: bold 12px arial, sans-serif;
  background: #fff;
}

.google_secret {
  align-items: center;
  justify-content: center;
  display: flex;
}

.el-dialog--center .el-dialog__footer {
  text-align: right;
}
.style-chooser .vs__selected {
  border-radius: 3px !important;
  border-color: #23CCEF;
  background-color: white;
  border-radius: 2px;
  color: #23CCEF;
}

.style-chooser .vs__deselect {
  background-color: transparent;
  color: #23CCEF;
  opacity: 1;
  font-size: 12px;
}

.style-chooser .vs__dropdown-option--selected {
  color: #23CCEF;
}

.style-chooser .prev-next {
  margin-top: 15px;
}

.style-chooser .prev-next button {
  margin-right: 15px;
  margin-left: 10px;
}
</style>
